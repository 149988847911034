(function () {
    'use strict';

    angular
        .module('mon.dialog', [
            'tandibar/ng-rollbar',
            'gettext',
        ]);
})();

(function () {
    'use strict';
    /**
     * monDialog
     *
     * @memberof mon.dialog
     * @ngdoc service
     * @name monDialog
     * @description
     * Creates dialog for monsido
     */

    angular
        .module('mon.dialog')
        .factory('monDialog', monDialog);

    monDialog.$inject = ['$document', '$templateCache', '$compile', '$rootScope', '$timeout', '$sce', 'Rollbar', 'ng2MonModalService', 'gettextCatalog', 'ng2TrapFocusService'];

    /* @ngInject*/
    function monDialog ($document, $templateCache, $compile, $rootScope, $timeout, $sce, Rollbar, ng2MonModalService, gettextCatalog, ng2TrapFocusService) {
        var dialogs = [];
        var doNotCallCallback = false;
        var openCallBack = null;
        var manualFocus = false;

        return {
            init: init,
            create: create,
            close: close,
            closeAll: closeAll,
            getDialog: getDialog,
        };

        // ///////////////


        /**
         * @memberOf monDialog
         * @description Setting up the dialog to be able to do callback on open
         * @param cb fn
         * @returns void
         */
        function init (cb) {
            openCallBack = cb;
        }

        /**
         * @description internal function to do the call back on open
         * @param options
         */
        function callCb (options) {
            if (openCallBack) {
                openCallBack(options);
            }
        }

        // jshint ignore: start
        /**
         * @memberOf monDialog
         * @description Create the overlay
         * @param options {header, body, size, classes, data, useStandardTemplate}
         * @param callBack fn
         * @returns {id}
         */
        function create (options, callBack, onBeforeClose) {
            manualFocus = options.manualFocus || false;
            var $el = angular.element;
            var body = $document[0].body;
            var scope = $rootScope.$new();
            var id = dialogId();

            if (typeof onBeforeClose === 'function') {
                scope.onBeforeClose = onBeforeClose;
            }

            scope.size = options.size || 'md';
            scope.classes = options.classes || '';
            scope.zindex = 10000;
            scope.id = id;
            scope.close = close;
            scope.useStandardTemplate = options.useStandardTemplate || false;
            scope.setForm = setForm;
            scope.unsavedMsg = options.unsavedMsg || gettextCatalog.getString('Are you sure you want to close this form without saving your changes?');
            scope.unsavedTitle = options.unsavedTitle || gettextCatalog.getString('Unsaved changes');
            var bodyComponent = options.body || null;
            if (bodyComponent) {
                scope.body = $sce.trustAsHtml(setupBodyComponent(bodyComponent, options.data || null, id, scope));
            }

            var headerComponent = options.header || null;
            if (headerComponent) {
                scope.header = $sce.trustAsHtml('<' + headerComponent + '></' + headerComponent + '>');
            }

            var dialogContainer = body;
            if (options.parentSelector) {
                dialogContainer = $document[0].querySelector(options.parentSelector) || body;
            }

            var overlay = createOverlay($el, dialogContainer, id, scope);
            var dialog = $compile($templateCache.get('app/dialog.html'))(scope).appendTo(dialogContainer);
            addDialog(id, dialog, overlay, callBack, scope);
            callCb(options);
            if (manualFocus !== true) {
                $timeout(function () {
                    var focus = ng2TrapFocusService.create('#' + id, {
                        escapeDeactivates: false,
                        allowOutsideClick: true,
                    });
                    setFocus(id, focus);
                }, 500);
            }
            return id;
        }

        /**
         * @memberOf monDialog
         * @description close overlay
         * @param id Id of overlay
         * @param data Data to return on close
         */
        async function close (id, data) {
            var dialog = getDialog(id);
            var index = dialogs.indexOf(dialog);

            if (angular.isObject(dialog) && index > -1) {
                const scope = dialog.scope;
                let allowToClose = true;

                if (scope && typeof scope.onBeforeClose === 'function') {
                    allowToClose = await scope.onBeforeClose();
                }

                if (allowToClose) {
                    if (dialog.scope.form && dialog.scope.form.$dirty === true) {
                        confirmClose(dialog, data);
                    } else {
                        closeDialog(dialog, data);
                    }
                }

            }
        }
        // jshint ignore: end

        /**
         * @memberOf monDialog
         * @description close overlay
         * @param dialog
         * @param data Data to return on close
         */

        function closeDialog (dialog, data) {
            var index = dialogs.indexOf(dialog);
            if (!dialog) {
                return;
            }
            ng2TrapFocusService.remove(dialog.focusTrap);
            dialog.dialog.addClass('mon-dialog-closeing');
            dialog.overlay.addClass('mon-dialog-overlay-fadeout');
            $timeout(function () {
                dialog.dialog.remove();
                dialog.overlay.remove();
                if (dialog.cb && !doNotCallCallback) {
                    dialog.cb(data);
                }
                dialog.scope.$destroy();
                dialogs.splice(index, 1);
            }, 500);
        }

        /**
         * @memberOf monDialog
         * @description confirm close dialog
         * @param dialog
         * @param data Data to return on close
         */

        function confirmClose (dialog, data) {
            ng2MonModalService.confirm({ message: dialog.scope.unsavedMsg, messageHTML: '', title: dialog.scope.unsavedTitle })
                .then(function () {
                    closeDialog(dialog, data);
                }, angular.noop);
        }


        /**
         * @memberOf monDialog
         * @description close all overlays
         * @param {function} callBack Callback when done
         * @param {Boolean} dontCallCallback - Set if callback should be run on close actions
         */
        function closeAll (callBack, dontCallCallback) {
            doNotCallCallback = Boolean(dontCallCallback);
            for (var i = 0; i < dialogs.length; i++) {
                close(dialogs[i].id);
            }

            $timeout(function () {
                if (callBack) {
                    callBack();
                }
                doNotCallCallback = false;
            }, 500);
        }

        /**
         * @memberOf monDialog
         * @description setup body component, map data
         * @param component
         * @param data
         * @param id
         * @param scope
         * @returns {string}
         */
        function setupBodyComponent (component, data, id, scope) {
            var componentAsString = '<' + setDashAtUppercase(component);
            componentAsString += ' set-form="setForm(id, form)" mon-dialog-id="' + id + '"';
            if (!angular.equals({}, data)) {
                for (var key in data) {
                    scope[key] = data[key];
                    componentAsString += ' ' + setDashAtUppercase(key) + '="' + key + '"';
                }
            }
            componentAsString += '></' + setDashAtUppercase(component) + '>';
            return componentAsString;
        }

        /**
         * setFocus
         * @param id
         * @param focus
         */
        function setFocus (id, focus) {
            if (id) {
                var dialog = getDialog(id);
                dialog.focusTrap = focus;
            }
        }

        /**
         * setForm
         * @param id
         * @param form
         */
        function setForm (id, form) {
            if (id) {
                var dialog = getDialog(id);
                dialog.scope.form = form;
            }
        }

        /**
         * @memberOf monDialog
         * @description replace dash at uppercase
         * @param word
         * @returns {string}
         */
        function setDashAtUppercase (word) {
            var i; var result = '';
            for (i = 0; i < word.length; i++) {
                if (word[i] === word[i].toUpperCase() && word[i] !== '-') {
                    result += '-';
                }
                result += word[i].toLowerCase();
            }
            return result;
        }

        /**
         * @memberOf monDialog
         * @description create transparent overlay
         * @param $el
         * @param body
         * @param id
         * @param scope
         * @returns {*}
         */
        function createOverlay ($el, body, id, scope) {
            var newEle = $el('<div class=\'mon-dialog-overlay ' + id + '\' ng-style="{\'z-index\': zindex}" ng-click="close(id)"></div>');
            $compile(newEle)(scope).appendTo(body);
            return newEle;
        }

        /**
         * @memberOf monDialog
         * @description add dialog to array
         * @param id
         * @param dialog
         * @param overlay
         * @param callBack
         * @param scope
         */
        function addDialog (id, dialog, overlay, callBack, scope) {
            dialogs.push({ id: id, dialog: dialog, overlay: overlay, cb: callBack, scope: scope });
        }

        /**
         * @memberOf monDialog
         * @description create id for overlay
         * @returns {string}
         */
        function dialogId () {
            return 'mon-dialog-id-' + Date.now();
        }

        /**
         * @memberOf monDialog
         * @description get dialog from array of dialogs
         * @throws {String} Throw String exception if no dialog was found
         * @param id
         */
        function getDialog (id) {
            var dialog = dialogs.find(function (dialog) {
                return dialog.id === id;
            });

            if (angular.isUndefined(dialog)) {
                Rollbar.warning('Dialog was not found', { availableDialogs: dialogs, dialogId: id });
            }

            return dialog;
        }
    }
})();

(function () {
    'use strict';

    angular.module('mon.dialog').directive('onEscape', function () {
        return {
            restrict: 'A',
            scope: {
                fn: '&onEscape',
            },
            link: function (scope, elem) {
                elem.on('keydown', function (event) {
                    if (event.key === 'Escape') {
                        scope.fn();
                    }
                    scope.$apply();
                });
            },
        };
    });
})();
