(function () {
    'use strict';

    angular
        .module('components.templates', []);

})();

(function (angular) {
    angular.module('msMomentCountries', []);
})(angular);

(function () {
    'use strict';

    angular
        .module('blocks.dialog', [
            'ngDialog',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts', [
            'constant',
            'app.components.charts.doughnut',
            'app.components.charts.line',
            'app.components.charts.barline',
            'app.components.charts.bar',
            'app.components.charts.stackedBar',
            'app.components.charts.horizontalBar',
            'app.components.charts.bubble',
            'app.components.charts.pie',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.colorPicker', [
            'mp.colorPicker',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.dialog', [
        ]);

})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder', [
            'msMomentCountries',
            'ui.bootstrap.datepickerPopup',
            'ui.bootstrap.datepicker',
            'selector',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.label', [
            'ui.bootstrap.pagination',
            'ui.bootstrap.buttons',
            'ui.bootstrap.dropdown',
            'core.constant',
            'blocks.dialog',
            'filters.labels',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.navigation', [
            'ui.bootstrap.tabs',
            'angular-multiple-transclusion',
        ]);

})();

(function () {
    'use strict';

    angular
        .module('app.components.panels', [
            'app.components.layout',
        ]);

})();

(function () {
    'use strict';

    angular
        .module('app.components.layout', [
            'ngNumeraljs',
            'ngSanitize',
            'ui.bootstrap.progressbar',
            'ui.bootstrap.popover',
            'gettext',
            'mon.dialog',
            'ngAvatar',
            'filters.monsido',
            'ui.bootstrap.tooltip',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.table', [
            'ui.bootstrap.pagination',
            'ui.bootstrap.buttons',
            'ui.bootstrap.dropdown',
            'ui.bootstrap.pager',
            'core.constant',
            'ngAnimate',
            'blocks.dialog',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.tabs', [
            'ui.bootstrap.tooltip',
        ]);

})();

(function () {
    'use strict';

    angular
        .module('filters.labels', [

        ]);
})();

(function () {
    'use strict';

    angular
        .module('filters.monsido', [

        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.bar', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.bubble', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.barline', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.doughnut', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.horizontalBar', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.line', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.pie', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.stackedBar', [
            'chart.js',
        ]);
})();

(function () {
    'use strict';

    angular.module('constant', []).constant('CHART', {
        HEIGHT: 340,
        CUTOUT_PERCENTAGE: 70,
        COLORS: {
            LIGHT_BLUE: '#299bf7',
            PEACH: '#ff9879',
            DARK_GREEN_BLUE: '#052942',
            YELLOW: '#fdc50d',
            PURPLE: '#5e3ea8',
            LIGHT_TURQUOISE: '#7beaf3',
            ORANGE: '#ff6c00',
            GREEN_BLUE: '#007a9e',
            LIGHT_PURPLE: '#837bc1',
            CERISE: '#d6347b',
            DARK_BLUE: '#004ac1',
            TURQUOISE: '#00dedb',
            RED: '#ba322d',
            LIGHT_YELLOW: '#efc271',
            PINK: '#f398c4',
            GRAY: '#9a9d9e',
            DARK_GRAY: '#555557',
            GREEN: '#26ff5c',
            LIGHT_GRAY: '#dfdfde',
            BLUE: '#205c9e',
        },
    });
})(angular);

(function () {
    'use strict';

    angular
        .module('blocks.dialog')
        .config(['ngDialogProvider', function (ngDialogProvider) {
            ngDialogProvider.setOpenOnePerName(false);
        }]);
})();

(function () {
    'use strict';

    angular
        .module('blocks.dialog')
        .factory('Dialog', Dialog);

    Dialog.$inject = ['ngDialog'];
    /* @ngInject*/
    function Dialog (ngDialog) {
        return {
            create: create,
            closeAll: closeAll,
            close: close,
        };

        // ///////////////

        function create (component, size, data, extraClass) {
            size = size || 'md';
            extraClass = extraClass || '';

            if (size.constructor === Object) {
                data = size;
                size = 'md';
            }
            return ngDialog.open({
                template: '<div bind-html-compile="vm.component"></div>',
                plain: true,
                controller: ['component', 'data', function (component, data) {
                    var vm = this;
                    var componentAsString = '<' + setDashAtUppercase(component);
                    if (!angular.equals({}, data)) {
                        for (var key in data) {
                            vm[key] = data[key];
                            componentAsString += ' ' + setDashAtUppercase(key) + '="vm.' + key + '"';
                        }
                    }
                    componentAsString += '></' + setDashAtUppercase(component) + '>';
                    vm.component = componentAsString;
                }],
                controllerAs: 'vm',
                className: 'ngdialog-theme-plain',
                appendClassName: 'ngdialog-' + size + ' ' + extraClass,
                overlay: true,
                cache: false,
                name: setDashAtUppercase(component),
                resolve: {
                    component: function () {
                        return component;
                    },
                    data: function () {
                        return data || {};
                    },
                },
            });
        }

        function closeAll (value) {
            ngDialog.closeAll(value);
        }

        function close (id, value) { // I don't like ngDialog. Can't even set our own instance id, tsk, tsk...
            var dialogIds = ngDialog.getOpenDialogs();
            var latestDialog = dialogIds[dialogIds.length - 1];
            ngDialog.close(latestDialog, value);
        }

        function setDashAtUppercase (word) {
            var i; var result = '';
            for (i = 0; i < word.length; i++) {
                if (word[i] === word[i].toUpperCase()) {
                    result += '-';
                }
                result += word[i].toLowerCase();
            }
            return result;
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('blocks.dialog')
        .directive('dialogWizard', wizard);

    wizard.$inject = [];
    function wizard () {
        return {
            bindToController: true,
            controller: WizardController,
            controllerAs: 'vm',
            restrict: 'A',
            template: '<button type="button" ng-show="!vm.firstStep" class="btn btn-default btn-secondary" ng-click="vm.prev()" translate>Previous</button>' +
            '<button type="button" ng-disabled="vm.validate" ng-show="!vm.lastStep" class="btn btn-default btn-secondary outer-l-small" ng-click="vm.next()" translate>Next</button>' +
            '<button type="button" ng-disabled="vm.validate" ng-show="vm.lastStep" class="btn btn-default btn-secondary outer-l-small" ng-click="vm.finish()" translate>Save</button>',
            scope: {
                'validate': '<?',
                'name': '<?',
            },
        };
    }

    WizardController.$inject = ['WizardHandler', '$scope'];
    /* @ngInject */
    function WizardController (WizardHandler, $scope) {
        var vm = this;

        vm.next = next;
        vm.prev = prev;
        vm.finish = finish;

        activate();

        // //////////

        function activate () {
            vm.lastStep = false;
            vm.firstStep = true;
            vm.wizardHandler = WizardHandler;
            $scope.$watch('vm.wizardHandler.wizard(vm.name).currentStep()', function (newVal) {
                if (vm.wizardHandler.wizard(vm.name) !== undefined) {
                    vm.firstStep = vm.wizardHandler.wizard(vm.name).currentStepNumber() == 1;
                    vm.lastStep = vm.wizardHandler.wizard(vm.name).currentStepNumber() == vm.wizardHandler.wizard(vm.name).totalStepCount();
                } else {
                    vm.firstStep = true;
                    vm.lastStep = false;
                }
            });
        }

        function prev () {
            vm.wizardHandler.wizard(vm.name).previous();
        }

        function next () {
            vm.wizardHandler.wizard(vm.name).next();
        }

        function finish () {
            vm.wizardHandler.wizard(vm.name).finish();
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('app.components.table')
        .directive('tableSort', tableSort);

    tableSort.$inject = ['$compile', 'gettextCatalog'];
    function tableSort ($compile, gettextCatalog) {
        return {
            bindToController: true,
            controller: SortController,
            controllerAs: 'vm',
            restrict: 'A',
            scope: {
                sortBy: '@',
                sortDirection: '<',
                getItems: '&',
                model: '<',
                startAsc: '<',
                thTitle: '<',
                tooltipClass: '<',
                tooltipText: '<',
            },
            link: link,
        };


        function link (scope, element, attrs, vm) {
            var ariaLabel = gettextCatalog.getString('Sort column');
            if (vm.model === vm.sortBy && vm.sortDirection === 'asc') {
                ariaLabel = gettextCatalog.getString('Sort column descending');
            }
            if (vm.model === vm.sortBy && vm.sortDirection === 'desc') {
                ariaLabel = gettextCatalog.getString('Sort column ascending');
            }

            var title = vm.thTitle || '';
            element.attr('ng-click', 'vm.clickHeader()');
            element.removeAttr('table-sort');
            element.attr('role', 'button');
            element.attr('aria-label', ariaLabel);
            $compile(element)(scope.$parent);
            element.on('click', vm.clickHeader);
            element.addClass('sort');

            var template = angular.element(
                '<button>' +
                title +
                '<span ng-if="vm.tooltipClass && vm.tooltipText" class="ml-1" ng-class="vm.tooltipClass" aria-hidden="true" uib-tooltip="{{vm.tooltipText}}" tooltip-trigger="\'focus mouseenter\'"></span>' +
                '<span class="inner-l-smurf fas fa-sort" ng-if="vm.model !== vm.sortBy"></span>' +
                '<span class="inner-l-smurf fas fa-sort-up" ng-if="vm.model === vm.sortBy && vm.sortDirection === \'asc\'"></span>' +
                '<span class="inner-l-smurf fas fa-sort-down" ng-if="vm.model === vm.sortBy && vm.sortDirection === \'desc\'"></span>' +
                '</button>',
            );
            element.append($compile(template)(scope));
        }
    }

    SortController.$inject = [];
    /* @ngInject */
    function SortController () {
        var vm = this;

        vm.clickHeader = clickHeader;

        // //////////

        function activate () {
            vm.model = vm.sortBy;
            if (vm.startAsc) {
                ascending();
            } else {
                descending();
            }
        }

        function deactivate () {
            onGetItems(null, null);
        }

        function ascending () {
            onGetItems('asc', vm.sortBy);
        }

        function descending () {
            onGetItems('desc', vm.sortBy);
        }

        function clickHeader () {
            if (vm.model === vm.sortBy) {
                if (vm.sortDirection === 'asc') {
                    if (vm.startAsc) {
                        descending();
                    } else {
                        deactivate();
                    }
                } else {
                    if (vm.startAsc) {
                        deactivate();
                    } else {
                        ascending();
                    }
                }
            } else {
                activate();
            }
        }

        function onGetItems (direction, by) {
            vm.getItems({ direction: direction, by: by });
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('app.components.charts')
        .config(['ChartJsProvider', '$windowProvider', function (ChartJsProvider, $windowProvider) {
            var $window = $windowProvider.$get();

            ChartJsProvider.setOptions({
                'maintainAspectRatio': false,
                // "responsive": true //default is true
            });

            var plugins = ChartJsProvider.$get().Chart.pluginService;

            // Draw background colors for line chart
            plugins.register({
                beforeDraw: function (chart, easing) {
                    var width; var height; var ctx;

                    if (chart.config.options.backgroundAreas) {
                        var chartArea = chart.chartArea;
                        var chatAreaWidth = chartArea.right - chartArea.left;
                        var backgroundAreas = chart.config.options.backgroundAreas;
                        var yScale = chart.scales['y-axis-0'];
                        ctx = chart.chart.ctx;
                        ctx.save();

                        for (var i = 0; i < backgroundAreas.length; i++) {
                            var area = backgroundAreas[i];
                            var top = yScale.getPixelForValue(area.range[1]);

                            height = yScale.getPixelForValue(area.range[0]) - top;

                            ctx.fillStyle = area.color;
                            ctx.fillRect(chartArea.left, top, chatAreaWidth, height);

                            ctx.restore();
                        }
                    }

                    if (chart.config.type === 'doughnut' || chart.config.type === 'pie') {
                        // Draw square doughnut chart canvas container
                        chart.config.options.maintainAspectRatio = true;
                        chart.chart.aspectRatio = 1;
                    }

                    if (chart.config.type === 'doughnut' && chart.config.options.centerText) {
                        // Draw text inside doughnut chart
                        width = chart.chart.width;
                        height = chart.chart.height;
                        ctx = chart.chart.ctx;
                        var lowestRange = width > height ? height : width;

                        var centerText = chart.config.options.centerText;
                        var hasPercentage = false;
                        var hasTotalLabel = false;
                        var hasSubLabel = false;
                        var totalFontSize = centerText.totalFontSize ? (lowestRange / centerText.totalFontSize).toFixed(2) : (lowestRange / 250).toFixed(2);
                        var percentageFontSize = centerText.percentageFontSize ? (lowestRange / centerText.percentageFontSize).toFixed(2) : (lowestRange / 100).toFixed(2);
                        var subFontSize = centerText.subFontSize ? (lowestRange / centerText.subFontSize).toFixed(2) : (lowestRange / 250).toFixed(2);

                        var totalFontWeight = centerText.totalFontWeight || 300;
                        var percentageFontWeight = centerText.percentageFontWeight || 400;
                        var subFontWeight = centerText.subFontWeight || 300;
                        var percentageLabel;
                        var totalLabel;
                        var subLabel;
                        var totalLabelTop = centerText.totalLabelTop;
                        var subLabelTop = centerText.subLabelTop;

                        if (centerText.percentageLabel) {
                            hasPercentage = true;
                            percentageLabel = removeDecimals(centerText.percentageLabel);
                        }
                        if (centerText.totalLabel) {
                            hasTotalLabel = true;
                            totalLabel = centerText.totalLabel;
                        }
                        if (centerText.subLabel) {
                            hasSubLabel = true;
                            subLabel = centerText.subLabel;
                        }

                        var centerLineLength = width - 2 * chart.radiusLength - height / 10;

                        // Draw only percentage label
                        if (hasPercentage && !hasTotalLabel && !hasSubLabel) {
                            ctx.restore();

                            ctx.textBaseline = 'middle';
                            drawText(chart, ctx, percentageLabel, percentageFontSize, percentageFontWeight, 0.5, false);

                            ctx.save();
                        }

                        // Draw only total (main) label
                        if (!hasPercentage && hasTotalLabel && !hasSubLabel) {
                            ctx.restore();

                            ctx.textBaseline = 'middle';
                            drawText(chart, ctx, totalLabel, totalFontSize, totalFontWeight, 0.5, centerLineLength);

                            ctx.save();
                        }

                        if (hasPercentage && hasTotalLabel && !hasSubLabel) {
                            ctx.restore();

                            ctx.textBaseline = 'middle';
                            drawText(chart, ctx, percentageLabel, percentageFontSize, percentageFontWeight, 0.4, false);

                            ctx.save();

                            drawText(chart, ctx, totalLabel, totalFontSize, totalFontWeight, 0.505 + totalFontSize * 10 / height, centerLineLength);
                            ctx.restore();

                            // Draw horizontal line
                            drawLine(ctx, chart.radiusLength + height / 20, height / 2, width - chart.radiusLength - height / 20, height / 2);

                            ctx.save();
                        }

                        // Draw all labels and horizontal line
                        if (hasPercentage && hasTotalLabel && hasSubLabel) {
                            ctx.restore();

                            ctx.textBaseline = 'middle';

                            drawText(chart, ctx, percentageLabel, percentageFontSize, percentageFontWeight, 0.4, false);

                            ctx.save();

                            drawText(chart, ctx, totalLabel, totalFontSize, totalFontWeight, 0.505 + totalFontSize * 10 / height, centerLineLength);
                            ctx.restore();
                            drawText(chart, ctx, subLabel, subFontSize, subFontWeight, 0.515 + totalFontSize * 15 / height + subFontSize * 10 / height, centerLineLength);

                            ctx.restore();

                            // Draw horizontal line
                            drawLine(ctx, chart.radiusLength + height / 20, height / 2, width - chart.radiusLength - height / 20, height / 2);

                            ctx.save();
                        }
                    }
                },
                beforeDatasetsDraw: function (chart) {
                    var ctx = chart.chart.ctx;
                    if (chart.config.options.horizontalLines && chart.config.options.horizontalLines.length > 0) {
                        var yScale = chart.scales['y-axis-0'];
                        var xScale = chart.scales['x-axis-0'];
                        var yValue;
                        var line;

                        for (var i = 0; i < chart.config.options.horizontalLines.length; i++) {
                            line = chart.config.options.horizontalLines[i];
                            ctx.save();

                            yValue = yScale.getPixelForValue(line.value);
                            drawLine(ctx, xScale.getPixelForTick(0), yValue, chart.chartArea.right, yValue, line.style, line.width);

                            ctx.restore();
                        }

                    }
                },
                afterDatasetsDraw: function (chart) {
                    var ctx = chart.chart.ctx;
                    var options = chart.config.options;

                    if (options.drawLabelOnChart) {
                        chart.data.datasets.forEach(function (dataset, i) {
                            var meta = chart.getDatasetMeta(i);
                            if (!meta.hidden) {
                                meta.data.forEach(function (element, index) {
                                    var dataString = dataset.data[index].toString();
                                    var padding = 5;
                                    var position = element.tooltipPosition();

                                    ctx.font = '400 1.1em Roboto';
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'middle';
                                    ctx.fillStyle = 'rgb(166,166,166)';

                                    ctx.fillText(dataString, position.x, position.y - 8 - padding);
                                });
                            }
                        });
                    }
                },
                afterDraw: function (chart) {
                    var ctx = chart.chart.ctx;
                    if (chart.data) {
                        var horizontalLinesToDraw = [];
                        var hiddenLines = [];
                        angular.forEach(chart.legend.legendItems, function (legendItem, index) {
                            if (legendItem.hidden === true) {
                                hiddenLines.push(index);
                            }
                        });
                        angular.forEach(chart.data.datasets, function (dataset, index) {
                            if (dataset.data.length === 1 && dataset.type === 'line' && hiddenLines.indexOf(index) === -1) {
                                horizontalLinesToDraw.push({
                                    value: dataset.data[0],
                                    xScale: dataset.xAxisID || 'x-axis-0',
                                    yScale: dataset.yAxisID || 'y-axis-0',
                                    color: dataset.borderColor,
                                });
                            }
                        });
                        angular.forEach(horizontalLinesToDraw, function (line) {
                            ctx.save();
                            drawLine(ctx,
                                chart.scales[line.xScale].left,
                                chart.scales[line.yScale].getPixelForValue(line.value),
                                chart.scales[line.xScale].right,
                                chart.scales[line.yScale].getPixelForValue(line.value),
                                line.color);
                            ctx.restore();
                        });
                    }
                },
            });


            // Vertical lines plugin
            var pluginState = {
                verticalLines: {
                    currentLinesPosition: [],
                    lastLinesPosition: [],
                    lastLinesTextBox: [],
                    tooltips: [],
                    currentChartId: null,
                },
            };
            var verticalLinePlugin = {
                DEFAULT_VALUES: {
                    LINE_DASH: [5, 5],
                    LINE_COLOR: '#000000',
                    LINE_WIDTH: 3,
                    ADDITION_PADDING_TOP: 20,
                    CIRCLE_TOP: -10,
                    CIRCLE_RADIUS: 6,
                    CIRCLE_HOVER_RADIUS: 7,
                },
                afterInit: function (chart) {
                    if (hasVerticalLinePlugin(chart)) {
                        verticalLinePluginInit(chart);
                    }
                },
                afterDatasetsDraw: function (chart) {
                    if (hasVerticalLinePlugin(chart)) {
                        verticalLinePluginDraw(chart);
                        verticalLinePluginAfterDraw(chart);
                    }
                },
                destroy: function () {
                    hideVerticalLineTooltip();
                    pluginState.verticalLines.currentLinesPosition = [];
                    pluginState.verticalLines.lastLinesPosition = [];
                    pluginState.verticalLines.lastLinesTextBox = [];
                    pluginState.verticalLines.tooltips = [];
                },
                beforeEvent: function (chart, event) {
                    if (onLeave) {
                        event.native.target.style.cursor = 'default';
                        onLeave = false;
                    }
                },
                afterEvent: function (chart, event) {
                    if (hasVerticalLinePlugin(chart)) {
                        verticalLinePluginEventHandler(chart, event);
                    }
                },
            };

            plugins.register(verticalLinePlugin);

            function verticalLinePluginInit (chart) {
                // Add padding to the top of the chart
                // To have more space to render the vertical line label
                chart.options.layout.padding.top += verticalLinePlugin.DEFAULT_VALUES.ADDITION_PADDING_TOP;
            }

            function verticalLinePluginDraw (chart) {
                var ctx = chart.chart.ctx;
                var verticalLines = chart.options.verticalLines;
                var firstXScale;
                for (var id in chart.scales) {
                    if (chart.scales.hasOwnProperty(id)) {
                        var s = chart.scales[id];
                        if (s._horizontal === true || s.position === 'bottom') {
                            firstXScale = s;
                            break;
                        }
                    }
                }
                verticalLines.lines.forEach(function (line, index) {
                    var xScale;
                    if (line.xAxisID) {
                        xScale = chart.scales[line.xAxisID];
                    } else {
                        // If xAxisID is not defined, use the first x axis scale
                        xScale = firstXScale;
                    }
                    // Render line
                    var lineX = xScale.getPixelForValue(line.x);
                    if (isNaN(lineX) && xScale.ticks && xScale.ticks.length > 0) {
                        // Unable to get pixel for provided value
                        // Possibly that the scale isn't a timescale
                        // Need to provide timeScale value else the line unable to be drawn correctly
                        if (!verticalLines.timeScale) {
                            throw new Error('The xScale isn\'t a time scale, "verticalLines.timeScale" value need to be provided. The value is an array of date string with the same length as the labels provided to the chart');
                        }
                        var timeScale = verticalLines.timeScale;
                        var xDateTimestamp = new Date(line.x).getTime();
                        var prevTick = 0;
                        var prevTickTimestamp = new Date(timeScale[0]).getTime();
                        if (xDateTimestamp < prevTickTimestamp) {
                            // lineX is positioned before the first tick
                            lineX = (xScale.left + xScale.getPixelForTick(0)) / 2 - 10;
                        } else {
                            for (var i = 1; i < timeScale.length; i++) {
                                var tickTimestamp = new Date(timeScale[i]).getTime();
                                if (prevTickTimestamp === xDateTimestamp) {
                                    lineX = xScale.getPixelForTick(i - 1);
                                    break;
                                }

                                if (tickTimestamp === xDateTimestamp) {
                                    lineX = xScale.getPixelForTick(i);
                                    break;
                                }

                                if (prevTickTimestamp < xDateTimestamp && xDateTimestamp < tickTimestamp) {
                                    // lineX is positioned between ticks
                                    var boundTickStart = xScale.getPixelForTick(prevTick);
                                    var boundTickEnd = xScale.getPixelForTick(i);
                                    lineX = (boundTickEnd + boundTickStart) / 2;
                                    break;
                                }
                                prevTick = i;
                                prevTickTimestamp = tickTimestamp;
                            }
                            if (isNaN(lineX)) {
                                // lineX is positioned after the last tick
                                lineX = (xScale.right + xScale.getPixelForValue(xScale.ticks[xScale.ticks.length - 1])) / 2 + 10;
                            }
                        }
                    }

                    var lineStyle;
                    var originalStyle = line.style || {};
                    lineStyle = {
                        lineDash: originalStyle.lineDash || verticalLinePlugin.DEFAULT_VALUES.LINE_DASH,
                        color: originalStyle.color || verticalLinePlugin.DEFAULT_VALUES.LINE_COLOR,
                        width: originalStyle.width || verticalLinePlugin.DEFAULT_VALUES.LINE_WIDTH,
                    };
                    var start = {
                        x: lineX,
                        y: xScale.top,
                    };
                    var end = {
                        x: lineX,
                        y: chart.chartArea.top,
                    };
                    ctx.save();
                    ctx.beginPath();
                    ctx.setLineDash(lineStyle.lineDash);
                    ctx.moveTo(start.x, start.y);
                    ctx.lineTo(end.x, end.y);
                    ctx.strokeStyle = lineStyle.color;
                    ctx.lineWidth = lineStyle.width;
                    ctx.stroke();
                    ctx.restore();

                    // Render circles
                    ctx.save();
                    var fillStyle = line.label.color || lineStyle.color || verticalLinePlugin.DEFAULT_VALUES.LINE_COLOR;
                    var strokeStyle = line.label.color || lineStyle.color || verticalLinePlugin.DEFAULT_VALUES.LINE_COLOR;

                    ctx.fillStyle = fillStyle;
                    ctx.strokeStyle = strokeStyle;
                    ctx.beginPath();
                    ctx.arc(lineX, end.y + verticalLinePlugin.DEFAULT_VALUES.CIRCLE_TOP, verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS, 0, 2 * Math.PI);
                    ctx.stroke();
                    ctx.fill();

                    ctx.restore();
                    var hoverDelta = verticalLinePlugin.DEFAULT_VALUES.CIRCLE_HOVER_RADIUS - verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS;
                    pluginState.verticalLines.lastLinesTextBox[index] = {
                        orgX: lineX - verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS,
                        orgY: end.y + verticalLinePlugin.DEFAULT_VALUES.CIRCLE_TOP - verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS,
                        x: lineX - verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS - hoverDelta,
                        y: end.y + verticalLinePlugin.DEFAULT_VALUES.CIRCLE_TOP - verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS - hoverDelta,
                        orgWidth: verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS * 2,
                        orgHeight: verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS * 2,
                        width: (verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS + hoverDelta) * 2,
                        height: (verticalLinePlugin.DEFAULT_VALUES.CIRCLE_RADIUS + hoverDelta) * 2,
                        fillStyle: fillStyle,
                        strokeStyle: strokeStyle,
                    };

                    pluginState.verticalLines.currentLinesPosition[index] = {
                        start: start,
                        end: end,
                    };

                });
            }

            function verticalLinePluginAfterDraw (chart) {
                var verticalLines = chart.options.verticalLines;

                pluginState.verticalLines.currentLinesPosition.forEach(function (linePosition, index) {
                    var start = linePosition.start;
                    var end = linePosition.end;
                    var lineX = start.x;
                    // Rendered hook
                    if (verticalLines.events && verticalLines.events.rendered) {
                        var lastLinePosition = pluginState.verticalLines.lastLinesPosition[index];
                        if (lastLinePosition === undefined || lastLinePosition === null || lineX !== lastLinePosition) {
                            verticalLines.events.rendered({
                                start: start,
                                end: end,
                            }, index);
                        }
                    }
                    pluginState.verticalLines.lastLinesPosition[index] = lineX;
                });
            }

            var onLeave = false;
            function verticalLinePluginEventHandler (chart, event) {
                var verticalLines = chart.options.verticalLines;
                var verticalLinesEvents = verticalLines.events;
                var allowedEvents = ['click', 'mousemove', 'mouseout'];

                if (pluginState.verticalLines.currentChartId !== chart.id) {
                    pluginState.verticalLines.lastLinesTextBox = [];
                    pluginState.verticalLines.currentChartId = chart.id;
                    return;
                }

                if (allowedEvents.indexOf(event.type) === -1) {
                    return;
                }

                var isHoveringOneLineLabel = false;
                var isClicked = false;

                pluginState.verticalLines.lastLinesTextBox.forEach(function (textBox, index) {
                    var line = verticalLines.lines[index];
                    if (!line) {
                        return;
                    }
                    if (isInRect(event.x, event.y, textBox.x, textBox.y, textBox.width, textBox.height)) {
                        event.native.target.style.cursor = 'pointer';
                        onLeave = true;
                        if (event.type === 'click' && !isClicked) {
                            if (line.data && line.data.link) {
                                $window.open(line.data.link, '_blank');
                                isClicked = true;
                            }
                        }
                        if (event.type === 'mousemove') {
                            isHoveringOneLineLabel = true;
                            chart.options.tooltips.enabled = false;
                            showVerticalLineTooltip(index, chart);
                            if (verticalLinesEvents && verticalLinesEvents.mouseover) {
                                verticalLinesEvents.mouseover(line.data, index);
                            }
                        }
                    }
                });

                if (!isHoveringOneLineLabel) {
                    chart.options.tooltips.enabled = true;
                    hideVerticalLineTooltip();
                }
            }

            function hasVerticalLinePlugin (chart) {
                return chart.config.options.verticalLines;
            }

            function showVerticalLineTooltip (index, chart) {
                var existedTooltip = pluginState.verticalLines.tooltips[index];
                var verticalLines = chart.options.verticalLines;
                var line = verticalLines.lines[index];
                var lastLinesTextBox = pluginState.verticalLines.lastLinesTextBox;
                var lineBoundRect = lastLinesTextBox[index];
                if (!existedTooltip) {
                    var tooltipEl = document.createElement('span');
                    var chartBoundingRect = chart.canvas.getBoundingClientRect();
                    var top = chartBoundingRect.top + lineBoundRect.y;
                    var left = chartBoundingRect.left + lineBoundRect.x + lineBoundRect.width / 2;
                    tooltipEl.textContent = line.label.text;
                    tooltipEl.setAttribute('class', 'mon-chartjs-vertical-line-tooltip');
                    document.body.appendChild(tooltipEl);

                    tooltipEl.style.top = top - tooltipEl.clientHeight - 5 + 'px';
                    tooltipEl.style.left = left - tooltipEl.clientWidth / 2 + 'px';
                    tooltipEl.classList.add('active');

                    pluginState.verticalLines.tooltips[index] = {
                        el: [tooltipEl],
                        remove: function () {
                            if (tooltipEl) {
                                tooltipEl.remove();
                            }
                        },
                    };

                }
            }

            function hideVerticalLineTooltip () {
                pluginState.verticalLines.tooltips.forEach(function (t, index) {
                    if (t) {
                        t.remove();
                        pluginState.verticalLines.tooltips[index] = null;
                    }
                });
            }

            //

            function isInRect (targetX, targetY, x, y, width, height) {
                return (
                    targetX >= x && targetX <= x + width && targetY >= y && targetY <= y + height
                );
            }

            function isTextOutOfContainer (ctx, text, chart) {
                return ctx.measureText(text).width > chart.chart.width - 2 * chart.radiusLength - chart.chart.height / 10;
            }

            function calculateScale (ctx, centerLineLength, text) {
                return centerLineLength / ctx.measureText(text).width;
            }

            function scaledX (chart, centerLineLength, text) {
                var ctx = chart.chart.ctx;
                var width = chart.chart.width;
                return ((width - ctx.measureText(text).width * centerLineLength / ctx.measureText(text).width) / 2) / (centerLineLength / ctx.measureText(text).width);
            }

            function scaledY (chart, centerLineLength, text, baseY) {
                var ctx = chart.chart.ctx;
                var height = chart.chart.height;
                return baseY * height / (centerLineLength / ctx.measureText(text).width);
            }

            function drawText (chart, ctx, text, textSize, fontWeight, topPercentage, centerLineLength) {
                var textX; var textY; var scale;
                var width = chart.chart.width;
                var height = chart.chart.height;
                ctx.font = fontWeight + ' ' + textSize + 'em Roboto';
                if (centerLineLength && isTextOutOfContainer(ctx, text, chart)) {
                    scale = calculateScale(ctx, centerLineLength, text);
                    ctx.scale(scale, scale);
                    textX = scaledX(chart, centerLineLength, text);
                    textY = scaledY(chart, centerLineLength, text, topPercentage);
                } else {
                    textX = Math.round((width - ctx.measureText(text).width) / 2);
                    textY = height * topPercentage;
                }
                ctx.fillText(text, textX, textY);
            }

            function drawLine (ctx, startX, startY, endX, endY, color, width) {
                ctx.beginPath();
                ctx.moveTo(startX, startY);
                ctx.lineTo(endX, endY);
                ctx.strokeStyle = color || '#eeeeee';
                ctx.lineWidth = width || 1;
                ctx.stroke();
            }

            /**
             *
             **/
            function removeDecimals (str) {
                var regex = /\d*\.\d*/; var num;
                if (str.indexOf('/') !== -1) {
                    var res = str.split('/');
                    for (var i = 0; i < res.length; i++) {
                        if (res[i].indexOf('.') !== -1) {
                            num = parseFloat(res[i].match(regex)[0]);
                            if (num === 100 || num === 0) {
                                res[i] = res[i].replace(regex, parseInt(num));
                            }
                        }
                    }
                    return res.join('/');
                } else {
                    if (str.indexOf('.') !== -1) {
                        num = parseFloat(str.match(regex)[0]);
                        if (num === 100 || num === 0) {
                            return str.replace(regex, parseInt(num));
                        }
                    }
                    return str;
                }

            }

        }]);
})();

(function () {
    'use strict';
    angular
        .module('app.components.colorPicker')
        .component('monColorPicker', {
            templateUrl: 'app/components/color-picker/color-picker.html',
            controller: ColorPickerController,
            controllerAs: 'vm',
            bindings: {
                color: '=',
                init: '<?',
            },
        });

    ColorPickerController.$inject = [];
    /* @ngInject */
    function ColorPickerController () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.displayColorSelector = false;
            vm.color = vm.color || '';
            vm.init = vm.init || '#fff';
            vm.presetColors = [];
            for (var i = 0; i < 9; i++) {
                vm.presetColors.push('#' + getRandomColor());
            }
        }

        function getRandomColor () {
            return fullColorHex(getRandomInt(0, 255), getRandomInt(0, 255), getRandomInt(0, 255));
        }

        function rgbToHex (rgb) {
            var hex = Number(rgb).toString(16);
            if (hex.length < 2) {
                hex = '0' + hex;
            }
            return hex;
        }

        function fullColorHex (r, g, b) {
            var red = rgbToHex(r);
            var green = rgbToHex(g);
            var blue = rgbToHex(b);
            return red + green + blue;
        }

        function getRandomInt (min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }
})();

(function () {
    'use strict';
    /**
     * Factory
     *
     * @memberof app.components.label
     * @ngdoc factory
     * @name LabelColorInverter
     * @description Service to calculate the luminance level of color input
     */
    angular
        .module('app.components.label')
        .factory('LabelColorInverter', LabelColorInverterFactory);

    LabelColorInverterFactory.$inject = [];
    /* @ngInject*/
    function LabelColorInverterFactory () {
        return {
            getTextColor: getTextColor,
        };

        /**
         * @memberOf LabelColorInverter
         * @param {String} hexColor - Hexadecimal color
         * @desc Return the appropriate inverted hexadecimal color according to WCAG 2.0 level AA
         * @return {string}
         */
        function getTextColor (hexColor) {
            if (typeof hexColor !== 'string') {
                return '#000000';
            }

            var background = getLuminance(hexColor);
            var foreground = getLuminance('#000000');
            var ratio = ( background + 0.05) / (foreground + 0.05); // ratio between foreground and background luminance
            if (ratio >= 4.5) {
                return '#000000'; // ratio needs to be at least 4.5 according to WCAG 2.0 level AA
            }
            return '#FFFFFF';
        }

        // PROTECTED

        /**
         * @memberOf LabelColorInverter
         * @param {String} c - A hex value of an hex color code
         * @return {number}
         * @desc Return the decimal value of a hex value
         */
        function getRGB (c) {
            var colorDecimal = parseInt(c, 16) / 255;
            if (colorDecimal <= 0.03928) {
                return colorDecimal / 12.92;
            } else {
                return Math.pow(((colorDecimal + 0.055) / 1.055), 2.4);
            }
        }

        /**
         * @memberOf LabelColorInverter
         * @param {String} hexColor - Hexadecimal color
         * @return {number}
         * @desc Return the luminance brightness number, ranging from 0 - 255
         */
        function getLuminance (hexColor) {
            return (0.2126 * getRGB(hexColor.substr(1, 2)) + 0.7152 * getRGB(hexColor.substr(3, 2)) + 0.0722 * getRGB(hexColor.substr(-2)));
        }
    }

})();

(function () {
    'use strict';
    angular
        .module('app.components.tabs')
        .directive('monTabs', function () {
            return {
                transclude: true,
                bindToController: true,
                restrict: 'E',
                templateUrl: 'app/components/tabs/tabs.html',
                controller: TabsController,
                link: TabsLinkController,
                controllerAs: 'vm',
                scope: {
                    status: '=',
                    options: '<',
                    onStatus: '&',
                    onSearch: '&',
                    search: '<?',
                    maxOptionsDisplay: '<?',
                    minSearchLength: '@?',
                    showSearch: '<?',
                    showFiltersBtn: '<?',
                    isFiltersOpen: '=?',
                },
            };
        });

    function TabsLinkController (scope, element, attrs, controller, transclude) {
        transclude(scope, function (clone) {
            scope.vm.transcludeHasContent = clone.length > 0;
        });
    }

    TabsController.$inject = ['$timeout', '$scope', 'gettextCatalog'];
    /* @ngInject */
    function TabsController ($timeout, $scope, gettextCatalog) {
        var vm = this;

        vm.callback = callback;
        vm.changeSearch = changeSearch;
        vm.toggleShowFilters = toggleShowFilters;
        vm.tabsCount = tabsCount;
        vm.$onInit = activate;
        vm.getFiltersTranslation = getFiltersTranslation;

        function activate () {
            vm.options = vm.options || [];
            vm.minSearchLength = parseInt(vm.minSearchLength) || 3;
            vm.search = vm.search || '';
            vm.moreOptions = [];
            vm.moreActive = false;
            vm.maxOptionsDisplay = parseInt(vm.maxOptionsDisplay) || 4;
            vm.showSearch = vm.showSearch || false;

            $scope.$watch('vm.options.length', function () {
                if (vm.options.length > vm.maxOptionsDisplay) {
                    vm.moreOptions = vm.options.splice(vm.maxOptionsDisplay);
                    vm.options = vm.options.splice(0, vm.maxOptionsDisplay);
                }
            });
        }

        function callback (tab) {
            if (vm.status != tab) {
                vm.status = tab;
                vm.moreActive = false;
                angular.forEach(vm.moreOptions, function (option) {
                    if (option.value == vm.status) {
                        vm.moreActive = true;
                        return;
                    }
                });
            }
            $timeout(vm.onStatus({ tab: vm.status }));
        }

        function changeSearch (search) {
            vm.search = search;
            $timeout(vm.onSearch({ search: vm.search }));
        }

        function toggleShowFilters () {
            vm.isFiltersOpen = !vm.isFiltersOpen;
        }

        function tabsCount () {
            return Object.keys(vm.options).length;
        }

        function getFiltersTranslation () {
            if (Boolean(vm.isFiltersOpen)) {
                return gettextCatalog.getString('Close filters');
            } else {
                return gettextCatalog.getString('Open filters');
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('filters.labels')
        .filter('inverseColorCode', InverseColorCodeFilter);

    InverseColorCodeFilter.$inject = ['LabelColorInverter'];
    /* @ngInject */
    function InverseColorCodeFilter (LabelColorInverter) {
        return inverseColorCode;

        // //////////

        function inverseColorCode (hex) {
            return LabelColorInverter.getTextColor(hex);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('filters.monsido')
        .filter('capitalize', CapitalizeFilterController);

    /* @ngInject */
    function CapitalizeFilterController () {
        return capitalize;

        // //////////

        function capitalize (word) {
            if (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('filters.monsido')
        .filter('escapeHtml', escape);

    /* @ngInject */
    function escape () {
        return escapeHtml;

        // //////////

        function escapeHtml (str) {
            var entityMap = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                '\'': '&#39;',
                '/': '/',
            };

            str = str || '';
            return String(str).replace(/[&<>"'\/]/g, function (s) {
                return entityMap[s];
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('filters.monsido')
        .filter('escapeRegExp', escapeRegExp);

    /* @ngInject */
    function escapeRegExp () {
        return escapeRegExpFilter;

        // //////////

        function escapeRegExpFilter (str) {
            str = str || '';
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('filters.monsido')
        .filter('generateUrlParams', GenerateUrlParamsFilterController);

    /* @ngInject */
    function GenerateUrlParamsFilterController () {
        return generateUrlParams;

        // //////////

        function generateUrlParams (params) {
            var result = [];
            for (var param in params) {
                result.push(param + '=' + params[param]);
            }
            return '?' + result.join('&');
        }
    }
})();

(function () {
    'use strict';

    angular.module('filters.monsido').filter('monRemoveWhiteSpace', monRemoveWhiteSpaceController);

    /* @ngInject */
    monRemoveWhiteSpaceController.$inject = [];
    function monRemoveWhiteSpaceController () {
        return monRemoveWhiteSpace;

        // //////////
        function monRemoveWhiteSpace (input) {
            if (typeof input === 'string') {
                return input.replace(/\s+/g, '');
            }
            return input;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('filters.monsido')
        .filter('returnHostname', returnHostnameController);

    /* @ngInject */
    function returnHostnameController () {
        return returnHostname;

        // //////////

        function returnHostname (url) {
            return url ? url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)[1] : '';
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('filters.monsido')
        .filter('userDate', UserDateController);

    /* @ngInject */
    UserDateController.$inject = ['$filter'];
    function UserDateController ($filter) {
        return userDateFilter;

        // //////////
        function userDateFilter (date, format) {
            format = format || 'll';
            return $filter('amDateFormat')(date, customFormats(format));
        }

        function customFormats (format) {
            switch (format) {
                case 'fullDate':
                    return 'dddd, MMMM D, YYYY';
                default:
                    return format;
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.bar')
        .component('chartBar', {
            transclude: true,
            templateUrl: 'app/components/charts/bar/bar.html',
            controller: ChartBarController,
            controllerAs: 'vm',
            bindings: {
                emptyDataMessage: '@?',
                labels: '<',
                data: '<',
                series: '<',
                override: '<?',
                options: '<?',
                height: '@?',
                colors: '<?',
                loading: '<?',
            },
        });

    ChartBarController.$inject = ['CHART', 'gettextCatalog', 'ng2LegendCursorService'];
    /* @ngInject */
    function ChartBarController (CHART, gettextCatalog, ng2LegendCursorService) {
        var vm = this;
        vm.$onInit = activate;
        vm.areAllDataRowsEmpty = areAllDataRowsEmpty;

        function activate () {
            vm.height = vm.height || CHART.HEIGHT;
            vm.emptyDataMessage = vm.emptyDataMessage || gettextCatalog.getString('No data present');
            vm.loading = vm.loading || false;
            vm.options = ng2LegendCursorService.setHoverState(vm.options);
        }

        function areAllDataRowsEmpty () {
            if (!Array.isArray(vm.data)) {
                return true;
            }

            for (var i = 0; i < vm.data.length; i++) {
                if (Array.isArray(vm.data[i])) {
                    if (vm.data[i].length > 0) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            return true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.bubble')
        .component('monChartBubble', {
            templateUrl: 'app/components/charts/bubble/bubble.html',
            controller: ChartBubbleController,
            controllerAs: 'vm',
            bindings: {
                height: '@?',
                data: '<',
                labels: '<',
                series: '<',
                options: '<',
                override: '<?',
                colors: '<?',
            },
        });

    ChartBubbleController.$inject = ['CHART', 'ng2LegendCursorService'];
    /* @ngInject */
    function ChartBubbleController (CHART, ng2LegendCursorService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.height = vm.height || CHART.HEIGHT;
            vm.options = ng2LegendCursorService.setHoverState(vm.options);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.barline')
        .component('chartBarline', {
            templateUrl: 'app/components/charts/barline/barline.html',
            controller: ChartLineController,
            controllerAs: 'vm',
            bindings: {
                labels: '<',
                data: '<',
                series: '<',
                override: '<?',
                options: '<?',
                height: '@?',
                colors: '<?',
                emptyDataMessage: '@?',
            },
        });

    ChartLineController.$inject = ['CHART', 'gettextCatalog', 'ng2LegendCursorService'];
    /* @ngInject */
    function ChartLineController (CHART, gettextCatalog, ng2LegendCursorService) {
        var vm = this;
        vm.colors = vm.colors || [
            CHART.COLORS.LIGHT_BLUE,
            CHART.COLORS.BLUE,
            CHART.COLORS.LIGHT_GRAY,
            CHART.COLORS.GREEN,
            CHART.COLORS.ORANGE,
            CHART.COLORS.GRAY,
            CHART.COLORS.DARK_GRAY,
        ];
        vm.override = vm.override || [];
        vm.options = vm.options || {
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                }],
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                    padding: 5,
                },
            },
            tooltips: {
                enabled: true,
                mode: 'x-axis',
                bodySpacing: 5,
            },
            hover: { mode: 'x-axis' },
        };
        vm.options = ng2LegendCursorService.setHoverState(vm.options);
        vm.$onInit = activate;
        vm.areAllDataRowsEmpty = areAllDataRowsEmpty;

        function activate () {
            vm.emptyDataMessage = vm.emptyDataMessage || gettextCatalog.getString('No data present');
            var pushToOverride = vm.override.length === 0;
            if (vm.options.scales && vm.options.scales.yAxes && pushToOverride) {
                vm.options.scales.yAxes.forEach(function (data, index) {
                    vm.override.push(chartDataSet(data.id, vm.colors[(index % vm.colors.length)]));
                });
            } else if (!(vm.options.scales && vm.options.scales.yAxes && vm.options.scales.yAxes.length > 0)) {
                if (!vm.options.scales) {
                    vm.options.scales = {};
                }
                if (!vm.options.scales.yAxes) {
                    vm.options.scales.yAxes = [];
                }

                vm.series.forEach(function (data, index) {
                    vm.options.scales.yAxes.push({
                        id: data,
                        type: 'linear',
                        display: (index === 0),
                        position: 'left',
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: 4,
                            maxTicksLimit: 5,
                        },
                    });

                    if (pushToOverride) {
                        vm.override.push(chartDataSet(data, vm.colors[(index % vm.colors.length)]));
                    }
                });
            }
        }

        function chartDataSet (ID, color) {
            return {
                yAxisID: ID,
                fill: false,
                borderWidth: 2.5,
                pointHoverRadius: 6,
                pointRadius: 4,
                pointBackgroundColor: '#fff',
                pointHoverBorderColor: '#fff',
                backgroundColor: 'rgba(' + color + ', 0.2)',
                borderColor: 'rgba(' + color + ', 1)',
                pointBorderColor: 'rgba(' + color + ', 1)',
                pointHoverBackgroundColor: 'rgba(' + color + ', 1)',
                pointHitRadius: 15,
                lineTension: 0.1,
            };
        }

        function areAllDataRowsEmpty () {
            if (!Array.isArray(vm.data)) {
                return true;
            }

            for (var i = 0; i < vm.data.length; i++) {
                if (Array.isArray(vm.data[i])) {
                    if (vm.data[i].length > 0) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.doughnut')
        .component('chartDoughnut', {
            templateUrl: 'app/components/charts/doughnut/doughnut.html',
            controller: ChartDoughnutController,
            controllerAs: 'vm',
            bindings: {
                labels: '<',
                data: '<',
                options: '<?',
                size: '@?',
                colors: '<',
            },
        });

    ChartDoughnutController.$inject = ['CHART', 'gettextCatalog', 'ng2LegendCursorService'];
    /* @ngInject */
    function ChartDoughnutController (CHART, gettextCatalog, ng2LegendCursorService) {
        var vm = this;

        vm.$onInit = activate;
        vm.$onChanges = update;

        function activate () {
            vm.colors = vm.colors || [
                CHART.COLORS.GREEN_BLUE,
                CHART.COLORS.LIGHT_GRAY,
            ]; // Default colors for doughnut chart

            vm.options = vm.options || {
                cutoutPercentage: CHART.CUTOUT_PERCENTAGE,
            };
            vm.options = ng2LegendCursorService.setHoverState(vm.options);
        }

        function update () {
            if (!vm.data) {
                return;
            }

            if (!(vm.options !== null && typeof vm.options === 'object')) {
                vm.options = {};
            }

            if (!(vm.options.centerText !== null && typeof vm.options.centerText === 'object')) {
                vm.options.centerText = {};
            }

            if (vm.data.filter(function (data) {
                return data > 0;
            }).length === 0) {
                vm.data = [0, 1];
                vm.options.centerText = {
                    totalFontSize: 150,
                    totalLabel: vm.options.centerText.emptyText ? vm.options.centerText.emptyText : gettextCatalog.getString('No data available'),
                };
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.horizontalBar')
        .component('chartHorizontalBar', {
            transclude: true,
            templateUrl: 'app/components/charts/horizontal-bar/horizontal-bar.html',
            controller: ChartHorizontalBarController,
            controllerAs: 'vm',
            bindings: {
                labels: '<',
                data: '<',
                series: '<',
                override: '<?',
                options: '<?',
                height: '@?',
                colors: '<?',
            },
        });

    ChartHorizontalBarController.$inject = ['CHART', 'ng2LegendCursorService'];
    /* @ngInject */
    function ChartHorizontalBarController (CHART, ng2LegendCursorService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.height = vm.height || CHART.HEIGHT;
            vm.options = ng2LegendCursorService.setHoverState(vm.options);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.line')
        .component('chartLine', {
            templateUrl: 'app/components/charts/line/line.html',
            controller: ChartLineController,
            controllerAs: 'vm',
            bindings: {
                labels: '<',
                data: '<',
                series: '<',
                override: '<?',
                options: '<?',
                height: '@?',
                colors: '<?',
                emptyDataMessage: '@?',
                progress: '<?',
            },
        });

    ChartLineController.$inject = ['$scope', 'CHART', 'gettextCatalog', 'ng2LegendCursorService'];
    /* @ngInject */
    function ChartLineController ($scope, CHART, gettextCatalog, ng2LegendCursorService) {
        var vm = this;
        vm.$onInit = activate;
        vm.areAllDataRowsEmpty = areAllDataRowsEmpty;

        function activate () {
            vm.progress = vm.progress || false;
            vm.getDataComplete = true;
            $scope.$watchCollection('vm.progress', function () {
                isProgressRunning();
            });
            isProgressRunning();
            vm.emptyDataMessage = vm.emptyDataMessage || gettextCatalog.getString('No data present');
            setupLineChart();
        }

        function setupLineChart () {
            vm.colors = vm.colors || [
                CHART.COLORS.LIGHT_BLUE,
                CHART.COLORS.BLUE,
                CHART.COLORS.LIGHT_GRAY,
                CHART.COLORS.GREEN,
                CHART.COLORS.ORANGE,
                CHART.COLORS.GRAY,
                CHART.COLORS.DARK_GRAY,
            ];
            vm.override = vm.override || [];
            vm.options = vm.options || {
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                    }],
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 10,
                        padding: 5,
                        fontSize: 11,
                        fontFamily: 'Roboto',
                        fontColor: '#A6A6A6',
                    },
                },
                tooltips: {
                    enabled: true,
                    mode: 'x-axis',
                    bodySpacing: 5,
                },
                hover: { mode: 'x-axis' },
            };
            if (vm.options.legend) {
                vm.options.legend.labels = {
                    boxWidth: 15,
                    padding: 15,
                    fontSize: 11,
                };
            }
            vm.options = ng2LegendCursorService.setHoverState(vm.options);

            var pushToOverride = vm.override.length === 0;
            if (vm.options.scales && vm.options.scales.yAxes && pushToOverride) {
                vm.options.scales.yAxes.forEach(function (data, index) {
                    vm.override.push(chartDataSet(data.id, toRgb(vm.colors[(index % vm.colors.length)])));
                });
            } else if (!(vm.options.scales && vm.options.scales.yAxes && vm.options.scales.yAxes.length > 0)) {
                if (!vm.options.scales) {
                    vm.options.scales = {};
                }
                if (!vm.options.scales.yAxes) {
                    vm.options.scales.yAxes = [];
                }

                vm.series.forEach(function (data, index) {
                    vm.options.scales.yAxes.push({
                        id: data,
                        type: 'linear',
                        display: (index === 0),
                        position: 'left',
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: 4,
                            maxTicksLimit: 5,
                        },
                    });

                    if (pushToOverride) {
                        vm.override.push(chartDataSet(data, toRgb(vm.colors[(index % vm.colors.length)])));
                    }
                });
            }
        }

        function toRgb (color) {
            if (/^#[0-9A-F]{6}$/i.test(color)) {
                var c = color.substring(1).split('');
                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = '0x' + c.join('');
                return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
            }

            return color;
        }

        function chartDataSet (ID, color) {
            return {
                yAxisID: ID,
                fill: false,
                borderWidth: 0,
                pointHoverRadius: 4,
                pointRadius: 3,
                pointBackgroundColor: '#fff',
                pointHoverBorderColor: '#fff',
                backgroundColor: 'rgba(' + color + ', 1)',
                borderColor: 'rgba(' + color + ', 1)',
                pointBorderColor: 'rgba(' + color + ', 1)',
                pointHoverBackgroundColor: 'rgba(' + color + ', 1)',
                pointHitRadius: 15,
                lineTension: 0.1,
            };
        }

        function areAllDataRowsEmpty () {
            if (!angular.isArray(vm.data)) {
                return true;
            }
            for (var i = 0; i < vm.data.length; i++) {
                if (angular.isArray(vm.data[i])) {
                    if (vm.data[i].length > 0) {
                        return false;
                    }
                } else {
                    return false;
                }
            }

            return true;
        }

        function isProgressRunning () {
            if (vm.progress !== false && vm.progress !== undefined && vm.progress !== null) {
                vm.getDataComplete = false;
                vm.progress.finally(function () {
                    vm.getDataComplete = true;
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.charts.pie')
        .component('chartPie', {
            templateUrl: 'app/components/charts/pie/pie.html',
            controller: ChartPieController,
            controllerAs: 'vm',
            bindings: {
                labels: '<',
                data: '<',
                options: '<?',
                size: '@?',
                colors: '<',
            },
        });

    ChartPieController.$inject = ['CHART', 'ng2LegendCursorService'];
    /* @ngInject */
    function ChartPieController (CHART, ng2LegendCursorService) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.colors = vm.colors || [
                CHART.COLORS.GREEN_BLUE,
                CHART.COLORS.LIGHT_GRAY,
            ]; // Default colors for pie chart
            vm.options = ng2LegendCursorService.setHoverState(vm.options);
        }

    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.formBuilder')
        .directive('monIndeterminate', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attributes) {
                    scope.$watch(attributes.monIndeterminate, function (value) {
                        element.prop('indeterminate', !!value);
                    });
                },
            };
        });
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('removePlaceholder', ['$timeout', function ($timeout) {
            var placeholderText = '';
            var linker = function (scope, element, attr) {
                element.bind('focus', function (e) {
                    placeholderText = e.currentTarget.placeholder;
                    e.currentTarget.placeholder = '';
                });

                element.bind('focusout', function (e) {
                    e.currentTarget.placeholder = placeholderText;
                });
            };

            return {
                restrict: 'A',
                link: linker,
            };
        }]);

})();

(function () {
    'use strict';
    angular
        .module('app.components.label')
        .component('labelContainer', {
            transclude: true,
            templateUrl: 'app/components/label/container/container.html',
            controller: LabelContainerComponent,
            controllerAs: 'vm',
            bindings: {
                currentLabels: '=',
                allLabels: '<',
                onUpdateLabel: '&',
                allowEdit: '<?',
                labelsNoBeforeMinimize: '<?',
                getLabels: '&?',
            },
        });
    LabelContainerComponent.$inject = [];
    /* @ngInject */
    function LabelContainerComponent () {
        var vm = this;
        vm.$onInit = active;

        function active () {
            vm.showLabelSelector = false;
            vm.currentLabels = vm.currentLabels || [];
            vm.allLabels = vm.allLabels || [];
            vm.labelsNoBeforeMinimize = vm.labelsNoBeforeMinimize || 2;
        }
    }
})();

(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name LabelTableService
     * @description Control Label Context
     */
    angular.module('app.components.label').factory('LabelTableService', LabelTableService);

    LabelTableService.$inject = ['rx'];
    /* @ngInject*/
    function LabelTableService (rx) {
        var service = {
            labelTxtVisibility: new rx.BehaviorSubject(false),
            editable: false,
            hideLabelTxt: hideLabelTxt,
            showLabelTxt: showLabelTxt,
            setEditable: setEditable,
        };

        return service;

        /**
         * @memberOf LabelTableService
         * @desc Hides the Label text in the Label Table Container
         * @return {void}
         */
        function hideLabelTxt () {
            service.labelTxtVisibility.onNext(false);
        }
        /**
         * @memberOf LabelTableService
         * @desc Shows the Label text in the Label Table Container
         * @return {void}
         */
        function showLabelTxt () {
            service.labelTxtVisibility.onNext(true);
        }

        /**
         * @memberOf LabelTableService
         * @desc Set editable variable
         * @return {void}
         */
        function setEditable (bool) {
            service.editable = Boolean(bool);
        }

        // PROTECTED
    }
})();

(function () {
    'use strict';

    angular.module('app.components.label').component('labelTable', {
        templateUrl: 'app/components/label/table/table.html',
        controller: LabelTableController,
        controllerAs: 'vm',
        bindings: {
            existingLabels: '<',
            assignedLabels: '<',
            getLabels: '&',
            onUpdateLabel: '&',
            noLabelSelector: '<?',
        },
    });

    LabelTableController.$inject = ['LabelTableService', '$filter'];
    function LabelTableController (LabelTableService, $filter) {
        var vm = this;
        vm.$onInit = activate;
        vm.getColorStyles = getColorStyles;
        vm.toggleTxtVisibility = toggleTxtVisibility;
        vm.toggleEdit = toggleEdit;
        vm.canEdit = canEdit;

        // ///////////////////////////////////////

        function activate () {
            vm.noLabelSelector = vm.noLabelSelector || false;
            vm.showTxt = false;
            vm.showLabelSelector = false;
            LabelTableService.labelTxtVisibility.subscribe(function (visibility) {
                vm.showTxt = visibility;
            });
        }

        function toggleTxtVisibility (event) {
            if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                event.stopImmediatePropagation();
                if (vm.showTxt) {
                    LabelTableService.hideLabelTxt();
                } else {
                    LabelTableService.showLabelTxt();
                }
            }
        }

        function getColorStyles (label) {
            var backgroundString = label.color;
            if (label.excluded && !vm.showTxt) {
                backgroundString = 'repeating-linear-gradient(to right, ' + label.color + ', ' + label.color + ' 4px' + ', white 4px, white 6px)';
            }
            return { 'background': backgroundString, color: $filter('inverseColorCode')(label.color) };
        }

        function canEdit () {
            return !vm.noLabelSelector && LabelTableService.editable;
        }

        function toggleEdit (event) {
            if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                event.stopImmediatePropagation();
                vm.showLabelSelector = !vm.showLabelSelector;
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .directive('monPanelHeader', function () {
            return {
                transclude: true,
                bindToController: true,
                replace: true,
                restrict: 'E',
                templateUrl: 'app/components/panels/panel-header/panel-header.html',
                controller: PanelHeaderController,
                controllerAs: 'vm',
                scope: {
                    pHeader: '@',
                    subHeader: '@',
                    faIcon: '@?',
                    eClass: '@?',
                    transcludeClass: '@?',
                    hideHr: '<?',
                },
            };
        });

    PanelHeaderController.$inject = [];
    /* @ngInject */
    function PanelHeaderController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.eClass += vm.hideHr ? ' no-border' : '';
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.panels')
        .component('monPanel', {
            transclude: {
                'header': '?panelHeader',
                'body': 'panelBody',
                'tabs': '?tabsExtra',
            },
            templateUrl: 'app/components/panels/panel/panel.html',
            controller: PanelController,
            controllerAs: 'vm',
            bindings: {
                header: '@?',
                subHeader: '@?',
                faIcon: '@?',
                tabOptions: '<?',
                activeTab: '<?',
                onSearch: '&?',
                onStatus: '&?',
                search: '<?',
                minSearchLength: '<?',
                showSearch: '<?',
                panelClass: '@?',
                hideHr: '<?',
                maxTabsDisplay: '<?',
            },
        });

    PanelController.$inject = [];

    function PanelController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.minSearchLength = vm.minSearchLength || 1;
            vm.showHeader = vm.header !== undefined;
            vm.tabOptions = vm.tabOptions || [];
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.layout')
        .component('userDate', {
            template: '<span class="text-nowrap" ng-if="vm.date && !vm.twoLines">{{ vm.date | userDate:\'ll LT\' }}</span>' +
            '<span class="text-nowrap d-inline-block" ng-if="vm.date && vm.twoLines">{{ vm.date | userDate:\'ll\' }}</span> ' +
            '<span class="text-nowrap" ng-if="vm.date && vm.twoLines">{{ vm.date | userDate:\'LT\' }}</span>',
            controller: UserDateController,
            controllerAs: 'vm',
            require: ['date'],
            bindings: {
                date: '<',
                twoLines: '<',
            },
        });

    UserDateController.$inject = [];

    function UserDateController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.twoLines = vm.twoLines || false;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.layout')
        .component('documentsButton', {
            template: '<pages-button ng-model="vm.ngModel" text="{{vm.text}}" text-plural="{{vm.textPlural}}" callback="vm.callback()">',
            controller: DocumentsButtonController,
            controllerAs: 'vm',
            bindings: {
                ngModel: '<',
                text: '@?',
                textPlural: '@?',
                callback: '&',
            },
        });

    /** This is just a pageButton wrapper for documents */
    DocumentsButtonController.$inject = ['gettextCatalog'];

    function DocumentsButtonController (gettextCatalog) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.model = parseInt(vm.ngModel);
            if (vm.model == 1) {
                vm.text = vm.text || gettextCatalog.getString('document');
            } else {
                vm.text = vm.textPlural || vm.text || gettextCatalog.getString('documents');
            }

            vm.textPlural = vm.textPlural || vm.text;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('filterButton', {
            templateUrl: 'app/components/layout/filter-button/filter-button.component.html',
            controller: FilterButtonController,
            controllerAs: 'vm',
            bindings: {
                showFiltersBtn: '<?',
                isFiltersOpen: '=?',
            },
        });

    FilterButtonController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function FilterButtonController (gettextCatalog) {
        var vm = this;
        vm.getFiltersTranslation = getFiltersTranslation;
        vm.toggleShowFilters = toggleShowFilters;

        function toggleShowFilters () {
            vm.isFiltersOpen = !vm.isFiltersOpen;
        }

        function getFiltersTranslation () {
            if (Boolean(vm.isFiltersOpen)) {
                return gettextCatalog.getString('Close filters');
            } else {
                return gettextCatalog.getString('Open filters');
            }
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.layout')
        .component('iconCircle', {
            template: '<div class="icon-circle" ng-class="vm.color"><span class="fa-lg icon" ng-class="vm.icon" aria-hidden="true"></span><span  tabindex="0" class="sr-only">{{vm.monAriaText}}</span></div>',
            controller: DomainStatusController,
            controllerAs: 'vm',
            bindings: {
                color: '<?',
                icon: '<',
                size: '@',
                monAriaText: '@?',
            },
        });

    DomainStatusController.$inject = [];
    /* @ngInject */
    function DomainStatusController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.color = vm.color || 'text-link';
            vm.monAriaText = vm.monAriaText || '';
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('notification', {
            transclude: true,
            templateUrl: 'app/components/layout/notification/notification.html',
            controller: NavigationController,
            controllerAs: 'vm',
            bindings: {
                callback: '&?',
                params: '<',
                link: '@?',
                faIcon: '@?',
                title: '@',
                digit: '<',
                allowDecimal: '<',
                classColor: '@?',
                miniDigits: '@?',
                unit: '@',
                monAriaIconText: '@?',
                monAriaButtonText: '@?',
            },
        });

    NavigationController.$inject = ['$state'];
    function NavigationController ($state) {
        var vm = this;
        vm.$onChanges = activate;
        vm.goTo = goTo;
        vm.displayPositive = displayPositive;
        vm.setMiniDigitsColor = setMiniDigitsColor;

        let isFirstChange = true;
        function activate (changes) {
            if (!changes.digit || isFirstChange) {
                isFirstChange = false;
                return;
            }
            vm.unit = vm.unit || null;

            if (vm.digit !== 0 && vm.digit < 1) {
                vm.allowDecimal = true;
            }
            if (vm.link || vm.params) {
                vm.noticationWithLink = vm.link || vm.params.target;
            } else {
                vm.noticationWithLink = null;
            }
        }

        function setMiniDigitsColor () {
            var result = '';
            if (vm.miniDigits < 0) {
                result = 'text-success';
            } else if (vm.miniDigits > 0) {
                result = 'text-danger';
            } else if (vm.miniDigits === '0') {
                result = 'text-grey';
            }
            return result;
        }

        function displayPositive (num) {
            var result = num;

            if (num > 0) {
                result = '+' + result.toString();
            }
            return result !== '0' ? result : '~';
        }

        function goTo () {
            if (vm.link) {
                vm.params = vm.params || {};
                $state.go(vm.link, vm.params);
            }
            if (vm.callback) {
                vm.params = vm.params || {};
                vm.callback({ params: vm.params });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('notificationChange', {
            transclude: true,
            templateUrl: 'app/components/layout/notification-change/notification-change.html',
            controller: NavigationController,
            controllerAs: 'vm',
            bindings: {
                params: '<',
                link: '@?',
                faIcon: '@?',
                title: '@',
                digit: '<',
                classColor: '@?',
                miniDigits: '@?',
                unit: '@',
                tooltipTitle: '@?',
            },
        });

    NavigationController.$inject = ['$state'];
    function NavigationController ($state) {
        var vm = this;
        vm.goTo = goTo;
        vm.displayPositive = displayPositive;
        vm.miniDigitsTextColor = miniDigitsTextColor;
        vm.miniDigitsIcon = miniDigitsIcon;
        vm.$onInit = activate;

        function activate () {
            vm.unit = vm.unit || null;
        }

        function displayPositive (num) {
            var result = parseInt(num);
            return result !== 0 ? Math.abs(result) : '';
        }

        function goTo () {
            if (vm.link) {
                vm.params = vm.params || {};
                $state.go(vm.link, vm.params);
            }
        }

        function miniDigitsTextColor () {
            if (vm.miniDigits < 0) {
                return 'text-light-green';
            } else if (vm.miniDigits > 0) {
                return 'text-danger';
            } else if (vm.miniDigits === '0') {
                return 'text-grey';
            }
        }

        function miniDigitsIcon () {
            if (vm.miniDigits < 0) {
                return 'text-light-green fas fa-chevron-down';
            } else if (vm.miniDigits > 0) {
                return 'fas fa-chevron-up text-danger';
            } else if (vm.miniDigits === '0') {
                return 'fa-tilde text-grey';
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('notificationText', {
            transclude: true,
            templateUrl: 'app/components/layout/notification-text/notification.html',
            controller: NavigationController,
            controllerAs: 'vm',
            bindings: {
                faIcon: '@?',
                title: '@',
                text: '@',
                miniText: '@',
                classColor: '@?',
            },
        });

    NavigationController.$inject = [];
    function NavigationController () {
        var vm = this;

    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.layout')
        .component('pagesButton', {
            templateUrl: 'app/components/layout/pages-button/pages-button.html',
            controller: IconButtonController,
            controllerAs: 'vm',
            bindings: {
                ngModel: '<',
                monAriaLabel: '@?',
                text: '@?',
                textPlural: '@?',
                callback: '&',
            },
        });

    IconButtonController.$inject = ['gettextCatalog'];

    function IconButtonController (gettextCatalog) {
        var vm = this;

        vm.$onInit = activate;
        vm.parseModel = parseModel;

        function activate () {
            vm.text = vm.text || gettextCatalog.getString('page');
            vm.textPlural = vm.textPlural || gettextCatalog.getString('pages');
            if (vm.monAriaLabel) {
                vm.label = vm.monAriaLabel + ' ' + vm.parseModel() + ' ' + vm.text;
            }
        }

        function parseModel () {
            return parseInt(vm.ngModel);
        }
    }
})();

(function () {
    'use strict';
    /**
     * page header  component
     *
     * @memberof app.components.layout
     * @ngdoc component
     * @name pageHeader
     * @param {String} headerType type of header (1 or 2)
     * @param {String} icon fontawesome icon to display
     * @param {String} header text to display
     * @param {String} subHeader text to display beneath header
     * @param {String} panelRightClass class to set on the right side
     * @param {String} panelLeftClass class to set on the left side
     * @param {Boolean} trustAsHtml parameter to define, if the header should parse html in the DOM for this component
     *
     */
    angular.module('app.components.layout').component('pageHeader', {
        transclude: {
            content: '?content',
        },
        templateUrl: 'app/components/layout/page-header/page-header.html',
        controller: PageHeaderController,
        controllerAs: 'vm',
        bindings: {
            headerType: '@?',
            icon: '@?',
            header: '@',
            subHeader: '@?',
            panelLeftClass: '@?',
            panelRightClass: '@?',
            panelClass: '@?',
            trustAsHtml: '<?',
            word: '@?',
            subHeaderTooltipPlacement: '@?', // https://angular-ui.github.io/bootstrap/#!#tooltip - 'tooltip-placement' attribute
            subHeaderTooltipEnable: '<?',
        },
    });

    PageHeaderController.$inject = ['$transclude'];
    /* @ngInject */
    function PageHeaderController ($transclude) {
        var vm = this;
        vm.$onInit = activate;
        vm.getLeftClass = getLeftClass;
        vm.getIconContainerClasses = getIconContainerClasses;
        vm.getRightClass = getRightClass;
        vm.isFilled = isFilled;
        vm.getWord = getWord;

        function activate () {
            vm.panelClass = vm.panelClass || '';
            vm.headerType = vm.headerType || '1';
            if (typeof vm.trustAsHtml !== 'boolean') {
                vm.trustAsHtml = true;
            }

            if (typeof vm.subHeaderTooltipPlacement !== 'string' || vm.subHeaderTooltipPlacement.trim().length === 0) {
                vm.subHeaderTooltipPlacement = 'bottom';
            }

            if (typeof vm.subHeaderTooltipEnable !== 'boolean') {
                vm.subHeaderTooltipEnable = true;
            }
        }

        function getIconContainerClasses () {
            var classes = [vm.icon];
            if (vm.headerType === '2') {
                classes.push('header-two');
            }
            return classes;
        }

        function getLeftClass () {
            if (typeof vm.panelLeftClass === 'string' && vm.panelLeftClass.length > 0) {
                return vm.panelLeftClass;
            }
            return '';
        }

        function getRightClass () {
            if (typeof vm.panelRightClass === 'string' && vm.panelRightClass.length > 0) {
                return vm.panelRightClass;
            }
            return '';
        }

        function isFilled () {
            return $transclude.isSlotFilled('content');
        }

        function getWord () {
            var headerWord = vm.header.split('- ');
            var word = headerWord[1];
            return word;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('popoverNotification', {
            transclude: true,
            templateUrl: 'app/components/layout/popover-notification/popover-notification.html',
            controller: PopoverNotificationController,
            controllerAs: 'vm',
            bindings: {
                title: '@',
                number: '@',
                pages: '@',
            },
        });

    PopoverNotificationController.$inject = [];
    /* @ngInject */
    function PopoverNotificationController () {
        var vm = this;
    }

})();

(function () {
    'use strict';
    angular
        .module('app.components.layout')
        .component('monPriority', {
            templateUrl: 'app/components/layout/priority/priority.html',
            controller: PriorityController,
            controllerAs: 'vm',
            bindings: {
                priority: '<',
            },
        });

    PriorityController.$inject = [];

    function PriorityController () {
        var vm = this;
        vm.getClass = getClass;

        vm.$onInit = activate;

        function activate () {
            vm.classes = {
                low: 'label-info',
                medium: 'label-warning',
                high: 'label-danger',
            };
        }

        function getClass () {
            var returnValue;
            angular.forEach(vm.classes, function (value, key) {
                if (key === vm.priority) {
                    returnValue = value;
                }
            });
            return returnValue;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.layout')
        .component('monProgressbar', {
            templateUrl: 'app/components/layout/progress/progress.html',
            controller: NotificationDescriptionController,
            controllerAs: 'vm',
            bindings: {
                maxRange: '@?',
                currentPosition: '@',
                type: '@?', // Options are 'success', 'info', 'warning', 'danger' and null
                message: '@?',
                information: '@?',
            },
        });

    NotificationDescriptionController.$inject = [];
    function NotificationDescriptionController () {
        var vm = this;
        vm.maxRange = vm.maxRange || 100;
        vm.label = vm.label || '';
        vm.message = vm.message || '';
        vm.information = vm.information || '';
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('monSpinner', {
            transclude: true,
            templateUrl: 'app/components/layout/spinner/spinner.html',
            controller: SpinnerController,
            controllerAs: 'vm',
            bindings: {
                progress: '<',
                loading: '<?',
            },
        });

    SpinnerController.$inject = ['$scope'];
    /* @ngInject */
    function SpinnerController ($scope) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            $scope.$watch('vm.progress', function () {
                runLoad();
            });
        }

        function runLoad () {
            if (vm.progress !== false && vm.progress !== undefined && vm.progress !== null) {
                vm.loading = true;
                vm.progress.finally(function () {
                    vm.loading = false;
                }, angular.noop);
            }
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('monSpinnerSnake', {
            templateUrl: 'app/components/layout/spinner-snake/spinner-snake.html',
        });

})();

(function () {
    'use strict';

    angular.module('app.components.layout').directive('urlTitle', function () {
        return {
            bindToController: true,
            templateUrl: 'app/components/layout/url-title/url-title.html',
            controller: PageTitleController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                title: '<',
                url: '<',
                urlTo: '<?',
                active: '<?',
                goTo: '&?',
                page: '<?',
                model: '<?',
                missingTitleText: '@?',
            },
        };
    });

    PageTitleController.$inject = ['monDialog'];
    /* @ngInject */
    function PageTitleController (monDialog) {
        var vm = this;

        vm.openPageDetails = openPageDetails;
        vm.getTitle = getTitle;
        vm.$onInit = activate;

        function activate () {
            vm.isFunction = false;
            vm.active = vm.active !== undefined ? vm.active : true;
            if (vm.urlTo === undefined) {
                vm.urlTo = vm.url;
            }
            if (vm.goTo) {
                vm.isFunction = true;
            }
        }

        function openPageDetails () {
            var params = {
                body: 'pageDetails',
                size: 'fw',
                classes: 'page-details-app',
                data: { page: vm.page },
            };
            monDialog.create(params);
        }

        function getTitle () {
            if (vm.title) {
                return vm.title;
            }

            if (vm.missingTitleText !== undefined) {
                return vm.missingTitleText;
            }

            return '(No title found)';
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.layout')
        .component('visits', {
            templateUrl: 'app/components/layout/visits/visits.html',
            controller: VisitsComponent,
            controllerAs: 'vm',
            bindings: {
                title: '@?',
                visits: '@',
                totalVisits: '@',
                removeNumber: '<?',
            },
        });

    VisitsComponent.$inject = [];
    /* @ngInject */
    function VisitsComponent () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.visits = parseInt(vm.visits);
            vm.randomId = guidGenerator();
            vm.totalVisits = parseInt(vm.totalVisits);
            vm.progress = 0;

            if (vm.totalVisits > 0) {
                vm.progress = (vm.visits / vm.totalVisits) * 100;
            }

            if (vm.progress > 100) {
                vm.progress = 100;
            }

            if (vm.progress < 0) {
                vm.progress = 0;
            }
        }


        function guidGenerator () {
            var S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.table')
        .component('tableCompliance', {
            templateUrl: 'app/components/table/compliance/compliance.html',
            controller: TableComplianceController,
            controllerAs: 'vm',
            bindings: {
                data: '<',
                labels: '<',
                indexNumber: '<', // Index of the number to evaluate from data
                size: '@?',
                btnWord: '@?',
            },
        });

    TableComplianceController.$inject = [];
    /* @ngInject */
    function TableComplianceController () {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.dataHasValue = dataHasValue;
        vm.parseInt = parseInt;

        function activate () {
            vm.size = vm.size || '35';
            vm.btnWord = vm.btnWord || 'Compliance';
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            vm.options = {
                cutoutPercentage: 57,
                maintainAspectRatio: false,
                responsive: false,
                tooltips: {
                    enabled: false,
                },
            };
        }

        function onChanges (changes) {
            if (changes.data &&
                changes.data.currentValue !== changes.data.previousValue &&
                Array.isArray(changes.data.currentValue) &&
                dataHasValue()) {
                evaluate();
            }
        }

        function dataHasValue () {
            return !vm.data.some(function (entry) {
                return isNaN(entry);
            });
        }

        function evaluate () {
            var number = 0; var collectedNumber = 0;
            vm.data.forEach(function (data, index) {
                if (index === vm.indexNumber) {
                    number = data;
                }
                collectedNumber += data;
            });
            vm.number = calculate((number / collectedNumber) * 100 || 0);
        }

        function calculate (num) {
            var multiplied = num * 100;
            multiplied = num < 1 ? Math.ceil(multiplied) : num > 99 ? Math.floor(multiplied) : Math.round(multiplied);
            return multiplied / 100;
        }
    }
})();

(function () {
    'use strict';
    angular.module('app.components.table').component('tableContainer', {
        transclude: {
            top: '?tableContainerTop',
        },
        templateUrl: 'app/components/table/container/container.html',
        controller: TableContainerComponent,
        controllerAs: 'vm',
        bindings: {
            disablePagination: '<',
            collection: '<',
            pageSizes: '<?',
            onPageChange: '&?',
            onPerPageChange: '&?',
            progress: '<',
            noResultMessage: '@?',
        },
    });

    TableContainerComponent.$inject = ['API_CONSTANTS', '$scope', '$timeout', '$q', 'gettextCatalog'];
    /* @ngInject */
    function TableContainerComponent (API_CONSTANTS, $scope, $timeout, $q, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.pageChange = pageChange;
        vm.pageSizeChange = pageSizeChange;
        vm.isProgressRunning = isProgressRunning;
        vm.showPagination = showPagination;
        vm.getPageSize = getPageSize;
        vm.shownNoResultMessage = shownNoResultMessage;

        function activate () {
            loading();
            vm.defaultPageSize = API_CONSTANTS.PAGE_SIZE;
            vm.disablePagination = vm.disablePagination || false;

            vm.progress = vm.progress || null;
            vm.collection = vm.collection || [];
            vm.pageSizes = vm.pageSizes || ['10', '25', '50'];
            vm.noResultMessage = vm.noResultMessage || gettextCatalog.getString('No content was found');
            setupWatchers();
        }

        function setupWatchers () {
            $scope.$watch('vm.progress', function (newValue, oldValue) {
                if (newValue !== null && newValue !== undefined && newValue !== false) {
                    isProgressRunning();
                } else {
                    doneLoading();
                }
            });
        }

        function showPagination () {
            return Number(vm.collection ? vm.collection.total : 0) > getPageSize();
        }

        function getPageSize () {
            var pageSize = vm.defaultPageSize;
            if (typeof vm.collection === 'object') {
                pageSize = vm.collection.perPage || pageSize;
            }

            return Number(pageSize);
        }

        function pageChange () {
            return vm.onPageChange({ page: vm.collection.currentPage });
        }

        function pageSizeChange (size) {
            return vm.onPerPageChange({ size: size });
        }

        function isProgressRunning () {
            loading();

            $q.when(vm.progress).finally(function () {
                $timeout(function () {
                    if (angular.isDefined(vm.collection)) {
                        vm.collection.total = vm.collection.total ? vm.collection.total : vm.collection.length;
                        doneLoading();
                        vm.showTable = vm.collection.total > 0;
                    } else {
                        doneLoading();
                    }
                });
            });
        }

        function shownNoResultMessage () {
            return !vm.showProgress && vm.collection.total == 0 && vm.noResultMessage.length > 0;
        }

        function loading () {
            vm.showProgress = true;
            vm.showTable = false;
        }

        function doneLoading () {
            vm.showProgress = false;
            vm.showTable = true;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.table')
        .component('tableContainerPager', {
            transclude: {
                'top': '?tableContainerTop',
            },
            templateUrl: 'app/components/table/container-pager/container-pager.html',
            controller: TableContainerPagerComponent,
            controllerAs: 'vm',
            bindings: {
                collection: '<',
                onPageChange: '&?',
                onPerPageChange: '&?',
                progress: '<',
                noResultMessage: '@?',
                pageSize: '<?',
                page: '<',
            },
        });

    TableContainerPagerComponent.$inject = ['API_CONSTANTS', '$scope', '$timeout', '$q'];
    /* @ngInject */
    function TableContainerPagerComponent (API_CONSTANTS, $scope, $timeout, $q) {
        var vm = this;
        vm.pageChange = pageChange;
        vm.isProgressRunning = isProgressRunning;
        vm.showPagination = showPagination;
        vm.getPageSize = getPageSize;
        vm.numPages = numPages;
        vm.$onInit = activate;
        vm.noPrevious = noPrevious;
        vm.noNext = noNext;

        function activate () {
            vm.showTable = true;
            vm.showProgress = false;
            vm.progress = vm.progress || false;
            vm.collection = vm.collection || [];
            vm.noResultMessage = vm.noResultMessage || 'No content was found';
            $scope.$watchCollection('vm.progress', function () {
                isProgressRunning();
            });
        }

        function showPagination () {
            return Number(vm.collection ? vm.collection.total : 0) > getPageSize();
        }

        function getPageSize () {
            var pageSize = API_CONSTANTS.PAGE_SIZE;
            if (typeof vm.pageSize !== 'undefined' && !isNaN(parseInt(vm.pageSize, 10))) {
                pageSize = parseInt(vm.pageSize, 10);
            }

            return Number(pageSize);
        }

        function pageChange (page) {
            return vm.onPageChange({ page: page });
        }

        function noPrevious () {
            if (!Array.isArray(vm.collection)) {
                return true;
            }
            return vm.page <= 1;
        }

        function noNext () {
            if (!Array.isArray(vm.collection)) {
                return true;
            }
            return vm.collection.length < vm.pageSize;
        }

        function numPages () {
            return 0;
        }

        function isProgressRunning () {
            vm.showProgress = true;
            vm.showTable = false;
            $q.when(vm.progress).finally(function () {
                $timeout(function () {
                    vm.showProgress = false;
                    vm.showTable = Boolean(vm.collection && vm.collection.length > 0);
                });
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.table')
        .component('tableDate', {
            template: '<div ng-show="vm.isValid" class="table-date"><span class="month">{{ vm.month }}</span><span class="day">{{ vm.day }}</span><span class="year">{{ vm.year }}</span></div>',
            controller: TableDate,
            controllerAs: 'vm',
            bindings: {
                date: '@',
            },
        });

    TableDate.$inject = [];
    /* @ngInject */
    function TableDate () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.isValid = moment(vm.date).isValid();
            if (vm.isValid) {
                var date = moment(vm.date);
                vm.day = date.format('DD');
                vm.month = date.format('MMM').toUpperCase();
                vm.year = date.format('YYYY');
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.table')
        .component('tableFilters', {
            transclude: true,
            controller: TableFilterController,
            controllerAs: 'vm',
            templateUrl: 'app/components/table/filters/filters.html',
            bindings: {
                filters: '<',
                ngModel: '<',
                open: '=',
                onChanges: '&',
                selectorItemTemplate: '<?',
            },
        });

    TableFilterController.$inject = [];
    /* @ngInject */
    function TableFilterController () {
        var vm = this;
        vm.$onInit = activate;
        vm.toggleSelector = toggleSelector;
        vm.clear = clear;

        function activate () {
            vm.ngModel = vm.ngModel || {};
            vm.open = vm.open || false;
        }

        function clear () {
            vm.ngModel = emptyModelData();
            vm.onChanges({ changes: vm.ngModel });
            vm.open = false;
        }

        function toggleSelector () {
            vm.showDropdown = !vm.showDropdown;
        }

        function emptyModelData () {
            var result = {};
            for (var key in vm.ngModel) {
                result[key] = [];
            }
            return result;
        }
    }

})();

(function () {
    'use strict';

    angular.module('app.components.table').component('tablePageSize', {
        controller: PageSizeController,
        controllerAs: 'vm',
        bindings: {
            pageSize: '@',
            pageSizes: '<?',
            onPerPageChange: '&',
            totalItems: '<?',
        },
        template:
            '<div class="btn-group" ng-show="vm.showPaginate()">' +
            '<a role="button" href="" aria-label="{{\'Page size\' | translate}} {{pageSize}}" class="btn btn-default btn-secondary" ng-model="vm.pageSize" ng-change="vm.sizeChange()" uib-btn-radio="pageSize" ng-repeat="pageSize in vm.pageSizes track by $index">{{pageSize}}</a>' +
            '</div>',
    });

    PageSizeController.$inject = ['API_CONSTANTS'];
    /* @ngInject */
    function PageSizeController (API_CONSTANTS) {
        var vm = this;

        vm.$onInit = activate;
        vm.sizeChange = sizeChange;
        vm.showPaginate = showPaginate;
        vm.$onChanges = onChanged;

        // //////////

        function activate () {
            vm.orgPageSizes = [];
            vm.pageSize = vm.pageSize || API_CONSTANTS.PAGE_SIZE;
            vm.totalItems = vm.totalItems || 0;
            vm.pageSizes = vm.pageSizes || ['10', '25', '50'];
            vm.orgPageSizes = angular.copy(
                vm.pageSizes.sort(function (a, b) {
                    return a - b;
                }),
            );
        }

        function showPaginate () {
            return Number(vm.pageSizes[0]) < Number(vm.totalItems);
        }

        function sizeChange () {
            vm.onPerPageChange({ size: vm.pageSize });
        }

        function onChanged () {
            if (vm.orgPageSizes) {
                setPageSizes();
            }
        }

        function setPageSizes () {
            var collectionCount = parseInt(vm.totalItems, 10);

            vm.pageSizes = vm.orgPageSizes.filter(function (pageSize, i) {
                return parseInt(pageSize) <= collectionCount || (i > 0 && vm.orgPageSizes[i - 1] < collectionCount);
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.table')
        .component('tableRefresh', {
            controllerAs: 'vm',
            bindings: {
                getPage: '&',
            },
            template: '<div class="table-refresh pull-right">' +
            '<span class="fas fa-sync-alt cursor-pointer" ng-click="vm.getPage()"></span>' +
            '</div>',
        });
})();

(function () {
    'use strict';
    angular
        .module('app.components.table')
        .component('tableSearch', {
            templateUrl: 'app/components/table/search/search.html',
            controller: TableSearch,
            controllerAs: 'vm',
            bindings: {
                getPage: '&',
                model: '<?',
                minSearchLength: '@',
                placeholderTxt: '@',
                containerClass: '<?',
                theme: '@?',
            },
        });

    TableSearch.$inject = ['gettextCatalog', '$timeout'];
    /* @ngInject */
    function TableSearch (gettextCatalog, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.changeSearch = changeSearch;
        vm.showClearBtn = showClearBtn;
        vm.onClearAction = onClearAction;

        function activate () {
            vm.searchDelay = null;
            vm.placeholderTxt = vm.placeholderTxt || gettextCatalog.getString('Search...');
            vm.minSearchLength = parseInt(vm.minSearchLength) || 3;
            vm.theme = vm.theme || 'white';
        }

        function changeSearch () {
            if (vm.searchDelay !== null) {
                $timeout.cancel(vm.searchDelay);
            }

            vm.searchDelay = $timeout(function () {
                if (vm.model.length >= vm.minSearchLength || vm.model.length === 0) {
                    vm.getPage({ search: vm.model });
                }
                vm.searchDelay = null;
            }, 400);
        }

        function onClearAction (e) {
            if (showClearBtn()) {
                vm.model = '';
                changeSearch();
            }
            e.currentTarget.parentElement.querySelector('input').focus();
        }

        function showClearBtn () {
            return typeof vm.model === 'string' && vm.model.length > 0;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.table')
        .component('tableSelectItemAll', {
            controller: SelectAllController,
            controllerAs: 'vm',
            bindings: {
                items: '<',
                selectedItems: '=',
                monLabel: '@?',
                disable: '<?',
            },
            template: '<input aria-label="{{vm.monLabel}}" ng-disabled="vm.disable" type="checkbox" ng-click="vm.toggleItems()" mon-indeterminate="vm.hasSomeItems()" ng-checked="vm.haveAllItems()" />',
        });

    SelectAllController.$inject = ['gettextCatalog', 'Lodash'];

    /* @ngInject */
    function SelectAllController (gettextCatalog, Lodash) {
        var vm = this;
        vm.$onInit = activate;
        vm.haveAllItems = haveAllItems;
        vm.hasSomeItems = hasSomeItems;
        vm.toggleItems = toggleItems;

        // ///////////

        function activate () {
            vm.monLabel = vm.monLabel || gettextCatalog.getString('Select all items');
            vm.items = vm.items || [];
            vm.selectedItems = vm.selectedItems || [];
            vm.disable = vm.disable || false;
        }

        function haveAllItems () {
            var filteredItems = vm.selectedItems.filter(function (item) {
                return indexOfItem(vm.items, item) > -1;
            });

            return filteredItems.length === vm.items.length;
        }

        function hasSomeItems () {
            if (haveAllItems()) {
                return false;
            }
            return Lodash.some(vm.items, function (item) {
                return indexOfItem(vm.selectedItems, item) > -1;
            });
        }

        function toggleItems () {
            if (!haveAllItems()) {
                vm.items.forEach(function (item) {
                    if (indexOfItem(vm.selectedItems, item) === -1) {
                        vm.selectedItems.push(item);
                    }
                });
            } else {
                vm.items.forEach(function (item) {
                    var index = indexOfItem(vm.selectedItems, item);
                    if (index > -1) {
                        vm.selectedItems.splice(index, 1);
                    }
                });
            }
        }

        // PROTECTED
        function indexOfItem (collection, val) {
            var stringified = stringify(val);
            for (var i = 0; i < collection.length; i++) {
                if (stringify(collection[i]) === stringified) {
                    return i;
                }
            }

            return -1;
        }

        function stringify (val) {
            return JSON.stringify(val, function (key, value) {
                var val = value;

                if (typeof key === 'string' && key.charAt(0) === '$' && key.charAt(1) === '$') { // Remove $$ key names
                    val = undefined;
                }

                return val;
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.table')
        .component('tableSelectItem', {
            controller: SelectItemController,
            controllerAs: 'vm',
            bindings: {
                item: '<',
                selectedItems: '=',
                monLabel: '@?',
                disable: '<?',
            },
            template: '<input aria-label="{{vm.monLabel}}" ng-disabled="vm.disable" ng-checked="vm.hasItem()" ng-click="vm.toggleItem()" type="checkbox"/>',
        });

    SelectItemController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function SelectItemController (gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.hasItem = hasItem;
        vm.toggleItem = toggleItem;
        // vm.selected = selected;

        // //////////

        function activate () {
            vm.monLabel = vm.monLabel || gettextCatalog.getString('Select item');
            vm.selectedItems = vm.selectedItems || [];
            vm.disable = vm.disable || false;
        }

        function hasItem () {
            return indexOfItem() > -1;
        }

        function toggleItem () {
            var index = indexOfItem();
            if (index === -1) {
                vm.selectedItems.push(vm.item);
            } else {
                vm.selectedItems.splice(index, 1);
            }
        }

        // PROTECTED
        function indexOfItem () {
            var stringified = stringify(vm.item);
            for (var i = 0; i < vm.selectedItems.length; i++) {
                if (stringify(vm.selectedItems[i]) === stringified) {
                    return i;
                }
            }

            return -1;
        }

        function stringify (val) {
            return JSON.stringify(val, function (key, value) {
                var val = value;

                if (typeof key === 'string' && key.charAt(0) === '$' && key.charAt(1) === '$') { // Remove $$ key names
                    val = undefined;
                }

                return val;
            });
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldCheckbox', function () {
            return {
                bindToController: true,
                restrict: 'E',
                templateUrl: 'app/components/form-builder/form-fields/checkbox/checkbox.html',
                controller: FormBuilderController,
                controllerAs: 'vm',
                link: FormFieldCheckboxLink,
                require: ['ngModel'],
                scope: {
                    ngModel: '=',
                    label: '@',
                    helpTxt: '@',
                    class: '@',
                    ngChange: '&?',
                },
            };
        });

    function FormFieldCheckboxLink (scope, element, attrs, controller, transcludeFn) {
        var input = element.find('input');
        input.bind('focusout', function () {
            controller[0].$setTouched();
            input.unbind('focusout');
        });

        input.bind('change', function () {
            controller[0].$setDirty();
        });
    }

    FormBuilderController.$inject = [];
    /* @ngInject */
    function FormBuilderController () {
        var vm = this;
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldDatepicker', function () {
            return {
                bindToController: true,
                restrict: 'E',
                templateUrl: 'app/components/form-builder/form-fields/datepicker/datepicker.html',
                controller: FormBuilderController,
                controllerAs: 'vm',
                link: FormFieldCheckboxLink,
                require: ['ngModel'],
                scope: {
                    ngModel: '=',
                    label: '@',
                    helpTxt: '@',
                    class: '@',
                },
            };
        });

    function FormFieldCheckboxLink (scope, element, attrs, controller, transcludeFn) {
        var input = element.find('input');
        input.bind('focusout', function () {
            controller[0].$setTouched();
            input.unbind('focusout');
        });

        input.bind('focus', function () {
            controller[0].$setDirty();
        });
    }

    FormBuilderController.$inject = [];
    /* @ngInject */
    function FormBuilderController () {
        var vm = this;
        var date = new Date();

        vm.dateOptions = {
            formatYear: 'yy',
            // minDate: date,
            startingDay: date.getDay(),
        };

        vm.format = 'MMM/dd/yyyy';
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldInput', function () {
            return {
                bindToController: true,
                restrict: 'E',
                templateUrl: 'app/components/form-builder/form-fields/input/input.html',
                controller: FormFieldInputController,
                controllerAs: 'vm',
                require: ['ngModel'],
                link: FormFieldInputLinkController,
                scope: {
                    ngModel: '=',
                    ngRequired: '<?',
                    label: '@',
                    helpTxt: '@',
                    placeholder: '@?',
                    autocomplete: '@?',
                    type: '@?',
                    disabled: '<?',
                    min: '<?',
                    max: '<?',
                },
            };
        });

    function FormFieldInputLinkController (scope, element, attrs, controller, transcludeFn) {
        var input = element.find('input');
        var formGroup = $(element[0].getElementsByClassName('form-group')[0]);
        var shouldWeValidateEmail = attrs.type && attrs.type == 'email';
        if (shouldWeValidateEmail) {
            validateEmail();
        }

        input.bind('focusout', function () {
            controller[0].$setTouched();
            input.unbind('focusout');
        });

        input.bind('keyup', function () {
            controller[0].$setDirty();
            if (shouldWeValidateEmail) {
                controller[0].$setViewValue(input.val());
                validateEmail();
            }
            if (controller[0].$invalid) {
                setHasError();
            } else {
                removeHasError();
            }
        });

        function validateEmail () {
            emailInputType(controller[0]);
            function emailInputType (ctrl) {
                var EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

                ctrl.$$parserName = 'email';
                ctrl.$validators.email = function (modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    return ctrl.$isEmpty(value) || EMAIL_REGEXP.test(value);
                };
            }
            controller[0].$validate();
        }

        function setHasError () {
            if (!formGroup.hasClass('has-error')) {
                formGroup.addClass('has-error');
            }
        }

        function removeHasError () {
            if (formGroup.hasClass('has-error')) {
                formGroup.removeClass('has-error');
            }
        }
    }

    FormFieldInputController.$inject = [];
    /* @ngInject */
    function FormFieldInputController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.ngRequired = vm.ngRequired || false;
            vm.type = vm.type || 'text';
        }
    }
})();

(function () {
    'use strict';
    /**
     * formFieldMaterialSwitch - material switch component
     *
     * @memberof app.components.formBuilder
     * @ngdoc component
     * @name formFieldMaterialSwitch
     * @param {*} ngModel Binding variable {Required}
     * @param {Function} ngChange function binding fired on change event
     * @param {String} name string binding, it is preferred that this is set to a unique name, to make sure that, the switcher doesn't flip any other checkboxes as well.
     * @param {String} color string binding, name of the variant the switch should take on, can only use label variants
     * @param {*} trueValue One way binding, can be set to anything, except undefined
     * @param {*} falseValue One way binding, can be set to anything, except undefined
     *
     */
    angular
        .module('app.components.formBuilder')
        .component('formFieldMaterialSwitch', {
            templateUrl: 'app/components/form-builder/form-fields/material-switch/material-switch.html',
            controller: FormFieldMaterialSwitchController,
            controllerAs: 'vm',
            bindings: {
                ngModel: '=',
                ngChange: '&?',
                name: '@', // If possible, please set a name on it, otherwise the for attribute on the label might act weird.
                color: '@?', // Options are. the same as any label variants we have available. Default value: 'primary'
                trueValue: '<?',
                falseValue: '<?',
            },
        });

    FormFieldMaterialSwitchController.$inject = [];
    /* @ngInject */
    function FormFieldMaterialSwitchController () {
        var vm = this;
        vm.$onInit = activate;
        vm.onClick = onClick;
        vm.onChange = onChange;

        /**
         * @memberOf formFieldMaterialSwitch
         * @description Initialize the formFieldMaterialSwitch component
         */
        function activate () {
            vm.trueValue = angular.isUndefined(vm.trueValue) ? true : vm.trueValue;
            vm.falseValue = angular.isUndefined(vm.falseValue) ? false : vm.falseValue;
            vm.ngModel = vm.ngModel === vm.trueValue ? vm.trueValue : vm.falseValue;
            vm.color = vm.color || 'label-primary';
        }

        /**
         * @memberOf formFieldMaterialSwitch
         * @description Runs on click event when button container is clicked
         */
        function onClick () {
            if (vm.ngModel === vm.trueValue) {
                vm.ngModel = vm.falseValue;
            } else {
                vm.ngModel = vm.trueValue;
            }
            onChange();
        }

        /**
         * @memberOf formFieldMaterialSwitch
         * @description Run ng change if function binding is applied
         */
        function onChange () {
            if (typeof vm.ngChange !== 'undefined') {
                vm.ngChange();
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldSelect', function () {
            return {
                bindToController: true,
                templateUrl: 'app/components/form-builder/form-fields/select/select.html',
                controller: FormFieldSelectController,
                controllerAs: 'vm',
                link: FormFieldSelectLinkController,
                require: ['ngModel'],
                scope: {
                    ngModel: '=',
                    options: '<',
                    label: '@',
                    helpTxt: '@?',
                    defaultOption: '@?',
                    returnValue: '@?',
                    ngRequired: '<?',
                    multiple: '<?',
                    group: '@?',
                    nameAttr: '@?',
                    ngChange: '&?', // Contains newValue & oldValue parameters
                    viewTemplate: '@?',
                    dropdownTemplate: '@?',
                    groupTemplate: '@?',
                    removeButton: '<?',
                    disabled: '<?',
                    disableSearch: '@?',
                    scrollEndOffset: '<',
                    onScrollEnd: '&?',
                },
            };
        });

    function FormFieldSelectLinkController (scope, element, attrs, controller, transcludeFn) {
        var input = element.find('select');
        input.bind('change', function () {
            controller[0].$setDirty();
        });
    }

    FormFieldSelectController.$inject = ['$timeout', '$element'];
    /* @ngInject */
    function FormFieldSelectController ($timeout, $element) {
        var vm = this;
        vm.$onInit = activate;
        vm.change = change;
        vm.$onChanges = onChanges;
        vm.scrollEndObserver = null;
        vm.scrollEndElement = null;

        function activate () {
            vm.defaultOption = vm.defaultOption || 'Choose an option';
            vm.returnValue = vm.returnValue || '';
            vm.nameAttr = vm.nameAttr || 'name';
            vm.containerWidth = vm.label ? 'col-sm-40' : 'col-sm-48';
            vm.ngRequired = vm.ngRequired || false;
            vm.multiple = vm.multiple || false;
            vm.group = vm.group || 'group';
            vm.removeButton = vm.removeButton || false;
            vm.disabled = vm.disabled || false;
            vm.disableSearch = vm.disableSearch || false;
            vm.scrollEndOffset = vm.scrollEndOffset || 10;
            setTemplates();
        }

        function onChanges (changes) {
            if (changes.options) {
                if (!vm.onScrollEnd) {
                    return;
                }
                vm.scrollEndElement = null;
                vm.scrollEndObserver = null;
                observeScrollToEdge();
            }
        }

        function observeScrollToEdge () {
            $timeout(() => {
                const dropdownListEl = $element[0].querySelector('.selector-dropdown');
                const selectorOptions = Array.from(dropdownListEl.querySelectorAll(`.selector-option`));

                if (selectorOptions.length === 0) {
                    return;
                }
                vm.scrollEndElement = selectorOptions[selectorOptions.length - vm.scrollEndOffset] ?? selectorOptions[selectorOptions.length - 1];

                if (vm.scrollEndElement) {
                    vm.scrollEndObserver = new IntersectionObserver((entries) => {
                        if (entries.some(entry => entry.isIntersecting)) {
                            vm.scrollEndObserver.unobserve(vm.scrollEndElement);
                            vm.onScrollEnd();
                        }
                    }, {
                        root: dropdownListEl,
                    });
                    vm.scrollEndObserver.observe(vm.scrollEndElement);
                }
            });
        }

        function setTemplates () {
            var templates = [{
                name: 'viewTemplate',
                value: 'selector/item-default.html',
            }, {
                name: 'dropdownTemplate',
                value: 'selector/item-default.html',
            }, {
                name: 'groupTemplate',
                value: 'selector/group-default.html',
            }];

            templates.forEach(function (val) {
                if (angular.isUndefined(vm[val.name]) || (angular.isString(vm[val.name]) && vm[val.name].length === 0)) {
                    vm[val.name] = val.value;
                }
            });
        }

        function change (newValue, oldValue) {
            if (!angular.isFunction(vm.ngChange)) {
                return;
            }
            $timeout(function () {
                vm.ngChange({ newValue: newValue, oldValue: oldValue });
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldTextarea', function () {
            return {
                bindToController: true,
                restrict: 'E',
                templateUrl: 'app/components/form-builder/form-fields/textarea/textarea.html',
                controller: FormFieldTextareaController,
                controllerAs: 'vm',
                require: ['ngModel'],
                link: FormFieldInputLinkController,
                scope: {
                    ngModel: '=',
                    ngRequired: '<?',
                    label: '@',
                    helpTxt: '@',
                    placeholder: '@?',
                    rows: '@?',
                },
            };
        });

    function FormFieldInputLinkController (scope, element, attrs, controller, transcludeFn) {
        var input = element.find('textarea');
        input.bind('focusout', function () {
            controller[0].$setTouched();
            input.unbind('focusout');
        });

        input.bind('keyup', function () {
            controller[0].$setDirty();
        });
    }

    FormFieldTextareaController.$inject = [];
    /* @ngInject */
    function FormFieldTextareaController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.ngRequired = vm.ngRequired || false;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.label')
        .component('labelContainerSelector', {
            transclude: true,
            templateUrl: 'app/components/label/container/selector/selector.html',
            controller: LabelContainerSelectorComponent,
            controllerAs: 'vm',
            bindings: {
                monShow: '=',
                currentLabels: '=',
                allLabels: '<',
                onUpdateLabel: '&',
                getLabels: '&',
            },
        });
    LabelContainerSelectorComponent.$inject = ['monDialog'];
    /* @ngInject */
    function LabelContainerSelectorComponent (monDialog) {
        var vm = this;

        vm.$onInit = active;
        vm.hasLabel = hasLabel;
        vm.clearLabel = clearLabel;
        vm.openLabelForm = openLabelForm;
        vm.updateLabel = updateLabel;

        function active () {
            vm.randomId = guidGenerator();
            vm.search = '';
        }

        function hasLabel (option) {
            return Array.isArray(vm.currentLabels) &&
                vm.currentLabels.length > 0 &&
                vm.currentLabels.some(function (label) {
                    return label.id === option.id;
                });
        }

        function updateLabel (label) {
            if (vm.currentLabels.length > 0 && hasLabel(label)) {
                vm.currentLabels.splice(labelIndex(label), 1);
            } else {
                vm.currentLabels.push(label);
            }
            vm.onUpdateLabel();
        }

        function clearLabel () {
            vm.currentLabels.length = 0;
            vm.onUpdateLabel();
        }

        function openLabelForm () {
            var params = {
                body: 'formLabel',
                size: 'xs',
                classes: 'fade-animation middle center label-dialog',
            };
            monDialog.create(params, function (label) {
                if (angular.isFunction(vm.getLabels)) {
                    vm.getLabels();
                }
                if (label && label.id) {
                    updateLabel(label);
                }
            });
        }

        // PROTECTED

        function guidGenerator () {
            var S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
        }

        function labelIndex (selectedLabel) {
            for (var i = 0; i < vm.currentLabels.length; i++) {
                if (selectedLabel.id === vm.currentLabels[i].id) {
                    return i;
                }
            }

            return -1;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .component('formFieldErrors', {
            transclude: true,
            templateUrl: 'app/components/form-builder/validations/messages/messages.html',
            controller: FormErrorsController,
            controllerAs: 'vm',
            bindings: {
                input: '<',
                patternMsg: '@?',
                offset: '@?',
            },
        });

    FormErrorsController.$inject = [];
    /* @ngInject */
    function FormErrorsController () {
        var vm = this;
        vm.$onInit = activate;
        vm.checkForErrors = checkForErrors;
        vm.gridSize = gridSize;

        function activate () {
            vm.offset = vm.offset || '8';
        }

        function gridSize (offset) {
            return 48 - offset;
        }

        function checkForErrors () {
            var result = false; var i;
            if (vm.input && vm.input.$error) {
                for (i in vm.input.$error) {
                    result = true;
                    break;
                }
            }
            return result;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.table')
        .component('tableFiltersSelectorItem', {
            transclude: true,
            templateUrl: 'app/components/table/filters/selector/item.html',
            controller: TableFiltersSelectorItemComponent,
            controllerAs: 'vm',
            bindings: {
                item: '<',
                filter: '<',
                selected: '<',
                template: '<?',
            },
        });

    TableFiltersSelectorItemComponent.$inject = [];
    /* @ngInject */
    function TableFiltersSelectorItemComponent () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.template = vm.template || 'app/components/table/filters/selector/templates/selector-default.html';
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('app.components.table')
        .component('tableFiltersSelector', {
            transclude: true,
            templateUrl: 'app/components/table/filters/selector/selector.html',
            controller: TableFiltersSelectorComponent,
            controllerAs: 'vm',
            bindings: {
                filters: '<',
                ngModel: '<',
                onChanges: '&',
                show: '<',
                showDropdown: '=?',
                selectorItemTemplate: '<?',
            },
        });

    TableFiltersSelectorComponent.$inject = ['$scope'];
    /* @ngInject */
    function TableFiltersSelectorComponent ($scope) {
        var vm = this;

        vm.toggleSubFilter = toggleSubFilter;
        vm.isSubFilterItemSelected = isSubFilterItemSelected;
        vm.onOverlayClick = onOverlayClick;
        vm.closeSubMenu = closeSubMenu;
        vm.isSubFilterArray = isSubFilterArray;
        vm.$onInit = active;

        function active () {
            vm.showDropdown = vm.showDropdown || false;
            vm.ngModel = vm.ngModel || {};

            $scope.$watch('vm.show', function (is, was) {
                if (was === false && is === true) {
                    vm.showDropdown = true;
                }
            });
        }

        function toggleSubFilter (ev, subFilter, item) {
            ev.stopPropagation();
            if (vm.ngModel[subFilter] === undefined) {
                return;
            }

            if (isSubFilterArray(vm.filters[subFilter])) {
                handleArraySubFilter(subFilter, item);
            } else {
                handleObjectSubFilter(subFilter, item);
            }
            changes();
        }

        function handleArraySubFilter (subFilter, item) {
            var itemIndex = vm.ngModel[subFilter].indexOf(item);
            if (itemIndex === -1) {
                vm.ngModel[subFilter].push(item);
            } else {
                vm.ngModel[subFilter].splice(itemIndex, 1);
            }
        }

        function handleObjectSubFilter (subFilter, item) {
            if (vm.ngModel[subFilter] && vm.ngModel[subFilter].length >= 1 && vm.ngModel[subFilter][0] === item) {
                vm.ngModel[subFilter] = null;
            } else {
                vm.ngModel[subFilter] = [item];
            }
        }

        function changes () {
            vm.onChanges({ changes: vm.ngModel });
        }

        function isSubFilterItemSelected (subFilter, item) {
            if (vm.ngModel[subFilter] === undefined) {
                return false;
            }

            if (isSubFilterArray(vm.filters[subFilter])) {
                var itemIndex = vm.ngModel[subFilter].indexOf(item);
                return itemIndex !== -1;
            } else {
                if (vm.ngModel[subFilter] && vm.ngModel[subFilter].length >= 1) {
                    return vm.ngModel[subFilter][0] === item;
                }
                return false;
            }
        }

        function onOverlayClick () {
            vm.showDropdown = false;
            vm.openSubMenu = null;
        }

        function closeSubMenu (ev) {
            ev.stopPropagation();
            vm.openSubMenu = false;
        }

        function isSubFilterArray (subfilter) {
            return Array.isArray(subfilter);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldCheckboxInLine',

            function () {
                return {
                    bindToController: true,
                    restrict: 'E',
                    link: FormFieldRadioInLineLinkController,
                    templateUrl: 'app/components/form-builder/form-fields/checkbox/inline/inline.html',
                    controller: FormFieldRadioInLineController,
                    controllerAs: 'vm',
                    require: ['ngModel'],
                    scope: {
                        ngModel: '=',
                        label: '@',
                        helpTxt: '@?',
                        options: '<',
                        returnValue: '@?',
                        ngRequired: '<?',
                        ngChange: '&?',
                    },
                };
            });

    function FormFieldRadioInLineLinkController (scope, element, attrs, controller, transcludeFn) {
        var input;
        var formGroup = $(element[0].getElementsByClassName('form-group')[0]);
        scope.$watch('options', function () {
            input = element.find('input');
            input.bind('focusout', function () {
                controller[0].$setTouched();
                input.unbind('focusout');
            });

            input.bind('change', function () {
                controller[0].$setDirty();
                if (controller[0].$invalid) {
                    setHasError();
                } else {
                    removeHasError();
                }
            });

            function setHasError () {
                if (!formGroup.hasClass('has-error')) {
                    formGroup.addClass('has-error');
                }
            }

            function removeHasError () {
                if (formGroup.hasClass('has-error')) {
                    formGroup.removeClass('has-error');
                }
            }
        });
    }

    FormFieldRadioInLineController.$inject = [];
    /* @ngInject */
    function FormFieldRadioInLineController () {
        var vm = this;
        vm.$onInit = activate;
        vm.toggleSelection = toggleSelection;
        vm.getIndex = getIndex;

        function activate () {
            vm.returnValue = vm.returnValue || 'name';
            vm.ngRequired = vm.ngRequired || false;
            vm.ngModel = vm.ngModel || [];
        }

        function toggleSelection (value) {
            var index = getIndex(value);
            if (index > -1) {
                vm.ngModel.splice(index, 1);
            } else {
                vm.ngModel.push(value);
            }
            vm.ngChange();
        }

        function getIndex (value) {
            var i; var index = -1;
            for (i = 0; i < vm.ngModel.length; i++) {
                if (vm.ngModel[i] === value) {
                    index = i;
                    break;
                }
            }
            return index;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('selectCountries', function () {
            return {
                bindToController: true,
                templateUrl: 'app/components/form-builder/form-fields/select/countries/countries.html',
                controller: FormFieldSelectCountriesController,
                link: FormFieldSelectCountriesLinkController,
                controllerAs: 'vm',
                require: ['ngModel'],
                scope: {
                    ngModel: '=',
                    label: '@',
                    helpTxt: '@?',
                    defaultOption: '@?',
                    returnValue: '@?',
                    ngRequired: '<?',
                    ngChange: '&?', // Contains newValue & oldValue parameters
                    viewTemplate: '@?',
                    dropdownTemplate: '@?',
                    groupTemplate: '@?',
                    removeButton: '<?',
                    disabled: '<?',
                },
            };
        });

    function FormFieldSelectCountriesLinkController (scope, element, attrs, controller, transcludeFn) {
        var input = element.find('form-field-select');
        input.bind('change', function () {
            controller[0].$setDirty();
        });
    }

    FormFieldSelectCountriesController.$inject = ['timeZone'];
    /* @ngInject */
    function FormFieldSelectCountriesController (timeZone) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.returnValue = vm.returnValue || 'value';
            vm.countries = [];
            vm.removeButton = vm.removeButton || false;
            vm.ngRequired = vm.ngRequired || false;
            vm.disabled = vm.disabled || false;
            setupCountries();
            setTemplates();
        }

        function setTemplates () {
            var templates = [{
                name: 'viewTemplate',
                value: 'selector/item-default.html',
            }, {
                name: 'dropdownTemplate',
                value: 'selector/item-default.html',
            }, {
                name: 'groupTemplate',
                value: 'selector/group-default.html',
            }];

            templates.forEach(function (val) {
                if (angular.isUndefined(vm[val.name]) || (angular.isString(vm[val.name]) && vm[val.name].length === 0)) {
                    vm[val.name] = val.value;
                }
            });
        }

        function setupCountries () {
            angular.forEach(timeZone.countries, function (value) {
                this.push(value);
            }, vm.countries);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('selectTimezones', function () {
            return {
                bindToController: true,
                templateUrl: 'app/components/form-builder/form-fields/select/timezones/timezones.html',
                controller: TimeZonesController,
                controllerAs: 'vm',
                require: ['ngModel'],
                link: FormFieldSelectTimeZonesLinkController,
                scope: {
                    ngModel: '=',
                    label: '@',
                    helpTxt: '@?',
                    defaultOption: '@?',
                    returnValue: '@?',
                    ngRequired: '<?',
                    multiple: '<?',
                    ngChange: '&?', // Contains newValue & oldValue parameters
                    viewTemplate: '@?',
                    dropdownTemplate: '@?',
                    removeButton: '<?',
                    disabled: '<?',
                },
            };
        });

    function FormFieldSelectTimeZonesLinkController (scope, element, attrs, controller, transcludeFn) {
        var input = element.find('form-field-select');
        input.bind('change', function () {
            controller[0].$setDirty();
        });
    }

    TimeZonesController.$inject = ['timeZone'];
    /* @ngInject */
    function TimeZonesController (timeZone) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.returnValue = vm.returnValue || 'value';
            vm.ngRequired = vm.ngRequired || false;
            vm.removeButton = vm.removeButton || false;
            vm.disabled = vm.disabled || false;
            vm.timezones = [];
            setupZones();
            setTemplates();
        }

        function setTemplates () {
            var templates = [{
                name: 'viewTemplate',
                value: 'selector/item-default.html',
            }, {
                name: 'dropdownTemplate',
                value: 'selector/item-default.html',
            }, {
                name: 'groupTemplate',
                value: 'selector/group-default.html',
            }];

            templates.forEach(function (val) {
                if (angular.isUndefined(vm[val.name]) || (angular.isString(vm[val.name]) && vm[val.name].length === 0)) {
                    vm[val.name] = val.value;
                }
            });
        }

        function setupZones () {
            angular.forEach(timeZone.zones, function (value, key) {
                this.push({ name: key, value: value.long });
            }, vm.timezones);

            // sorts array by longitude
            vm.timezones.sort(function (a,b) {
                return (a.value > b.value) ? 1 : -1;
            });

            vm.timezones.forEach(function (object) {
                object.value = object.name;
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldRadioInLine',

            function () {
                return {
                    bindToController: true,
                    restrict: 'E',
                    link: FormFieldRadioInLineLinkController,
                    templateUrl: 'app/components/form-builder/form-fields/radio/inline/inline.html',
                    controller: RadioController,
                    controllerAs: 'vm',
                    require: ['ngModel'],
                    scope: {
                        ngModel: '=',
                        label: '@',
                        helpTxt: '@?',
                        options: '<',
                        returnValue: '@?',
                        ngRequired: '<?',
                        ngChange: '&?',
                    },
                };
            });

    function FormFieldRadioInLineLinkController (scope, element, attrs, controller, transcludeFn) {
        var input;
        var formGroup = $(element[0].getElementsByClassName('form-group')[0]);
        scope.$watch('options', function () {
            input = element.find('input');
            input.bind('focusout', function () {
                controller[0].$setTouched();
                input.unbind('focusout');
            });

            input.bind('change', function () {
                controller[0].$setDirty();
                if (controller[0].$invalid) {
                    setHasError();
                } else {
                    removeHasError();
                }
            });

            function setHasError () {
                if (!formGroup.hasClass('has-error')) {
                    formGroup.addClass('has-error');
                }
            }

            function removeHasError () {
                if (formGroup.hasClass('has-error')) {
                    formGroup.removeClass('has-error');
                }
            }
        });
    }

    RadioController.$inject = [];
    /* @ngInject */
    function RadioController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.returnValue = vm.returnValue || 'name';
            vm.ngRequired = vm.ngRequired || false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.components.formBuilder')
        .directive('formFieldRadioNewLine', function () {
            return {
                bindToController: true,
                restrict: 'E',
                link: FormFieldRadioNewLineLinkController,
                templateUrl: 'app/components/form-builder/form-fields/radio/newline/newline.html',
                controller: RadioController,
                controllerAs: 'vm',
                require: ['ngModel'],
                scope: {
                    ngModel: '=',
                    label: '@',
                    helpTxt: '@?',
                    options: '<',
                    returnValue: '@?',
                    ngRequired: '<?',
                    ngChange: '&?',
                },
            };
        });


    function FormFieldRadioNewLineLinkController (scope, element, attrs, controller, transcludeFn) {
        var input;
        var formGroup = $(element[0].getElementsByClassName('form-group')[0]);
        scope.$watch('options', function () {
            input = element.find('input');
            input.bind('focusout', function () {
                controller[0].$setTouched();
                input.unbind('focusout');
            });

            input.bind('change', function () {
                controller[0].$setDirty();
                if (controller[0].$invalid) {
                    setHasError();
                } else {
                    removeHasError();
                }
            });

            function setHasError () {
                if (!formGroup.hasClass('has-error')) {
                    formGroup.addClass('has-error');
                }
            }

            function removeHasError () {
                if (formGroup.hasClass('has-error')) {
                    formGroup.removeClass('has-error');
                }
            }
        });
    }


    RadioController.$inject = [];
    /* @ngInject */
    function RadioController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.returnValue = vm.returnValue || 'name';
            vm.ngRequired = vm.ngRequired || false;
        }
    }
})();
