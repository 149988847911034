(function () {

    angular
        .module('mon.helpers', [
            'mon.helpers.base64',
            'mon.helpers.focus',
            'mon.helpers.loading',
            'mon.helpers.param',
            'mon.helpers.copy',
            'mon.helpers.directives',
            'mon.helpers.alerts',
        ]);
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.alerts', []);
})();

(function () {

    angular
        .module('mon.helpers.base64', []);
})();

(function () {

    angular
        .module('mon.helpers.focus', []);
})();
(function () {
    'use strict';

    angular
        .module('mon.helpers.copy', [
            'ui.bootstrap.tooltip',
            'ngclipboard',
        ]);
})();
(function () {
    'use strict';

    angular
        .module('mon.helpers.directives', [

        ]);
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.loading', []);
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.param', []);
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.alerts')
        .component('monsidoAlerts', {
            templateUrl: 'mon/helpers/alerts/alerts.html',
        });
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.base64')
        .factory('Base64', Base64);

    /* @ngInject */
    function Base64 () {
        var keyStr =
                'ABCDEFGHIJKLMNOP' +
                'QRSTUVWXYZabcdef' +
                'ghijklmnopqrstuv' +
                'wxyz0123456789+/' +
                '=';

        return {
            encode: encode,
            encodeUnicode: encodeUnicode,
            decode: decode,
        };

        // ///////////////

        function encode (input) {
            var output = '';
            var enc1; var enc2; var enc3; var enc4 = '';
            var chr1; var chr2; var chr3 = '';
            var i = 0;

            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                    keyStr.charAt(enc1) +
                    keyStr.charAt(enc2) +
                    keyStr.charAt(enc3) +
                    keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = '';
                enc1 = enc2 = enc3 = enc4 = '';
            } while (i < input.length);

            return output;
        }

        function encodeUnicode (str) {
            str = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode('0x' + p1);
            });
            return encode(str);
        }

        function decode (input) {
            var output = '';
            var chr1; var chr2; var chr3 = '';
            var enc1; var enc2; var enc3; var enc4 = '';
            var i = 0;

            // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
            var base64test = /[^A-Za-z0-9\+\/\=]/g;
            if (base64test.exec(input)) {
                alert('There were invalid base64 characters in the input text.\n' +
                    'Valid base64 characters are A-Z, a-z, 0-9, \'+\', \'/\',and \'=\'\n' +
                    'Expect errors in decoding.');
            }
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

            do {
                enc1 = keyStr.indexOf(input.charAt(i++));
                enc2 = keyStr.indexOf(input.charAt(i++));
                enc3 = keyStr.indexOf(input.charAt(i++));
                enc4 = keyStr.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 !== 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 !== 64) {
                    output = output + String.fromCharCode(chr3);
                }

                chr1 = chr2 = chr3 = '';
                enc1 = enc2 = enc3 = enc4 = '';

            } while (i < input.length);

            return output;
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.focus')
        .factory('focus', focus);

    focus.$inject = ['$timeout', '$window'];
    /* @ngInject*/
    function focus ($timeout, $window) {
        function service (id) {
            return $timeout(function () {
                var element = $window.document.getElementById(id);
                if (element) {
                    element.focus();
                }
            });
        }
        return service;
    }

})();

(function () {
    'use strict';
    angular.module('mon.helpers.copy').component('copyDialogComponent', {
        templateUrl: 'mon/helpers/copy/dialog/dialog.html',
        controller: CopyDialogController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
        },
    });

    CopyDialogController.$inject = ['gettext'];

    function CopyDialogController (gettext) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.headline = vm.resolve.headline || gettext('Copy');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.copy')
        .factory('copyDialogService', copyDialogService);

    copyDialogService.$inject = ['$uibModal'];
    /* @ngInject*/
    function copyDialogService ($uibModal) {

        return {
            open: open,
        };

        // ///////////////

        function open (copyString, headline, cb) {
            $uibModal
                .open({
                    component: 'copyDialogComponent',
                    resolve: {
                        copyString: function () {
                            return copyString;
                        },
                        headline: function () {
                            return headline;
                        },
                    },
                })
                .result.then(function () {
                    if (cb) {
                        cb();
                    }
                }, angular.noop);
        }
    }
})();

(function () {
    'use strict';
    /**
     * monCopy - copy component
     *
     * @memberof app.components.copy
     * @ngdoc component
     * @name monCopy
     * @param {String} target String to copy
     *
     */
    angular
        .module('mon.helpers.copy')
        .component('monCopy', {
            templateUrl: 'mon/helpers/copy/copy.html',
            controller: CopyController,
            controllerAs: 'vm',
            bindings: {
                target: '@',
            },
        });

    CopyController.$inject = ['ng2DeviceDetectorService', 'gettext'];
    /* @ngInject */

    function CopyController (ng2DeviceDetectorService, gettext) {
        var vm = this;

        vm.onError = onError;
        vm.tooltip = gettext('Copied!');
        vm.$onInit = activate;

        function activate () {
            vm.isReady = true;
        }

        function onError (e) {
            if (ng2DeviceDetectorService.isMobile()) {
                vm.tooltip = gettext('No support');
            } else if (ng2DeviceDetectorService.os === 'mac') {
                vm.tooltip = gettext('Press ⌘-C');
            } else {
                vm.tooltip = gettext('Press Ctrl-C');
            }
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('confirmOnExit', confirmOnExit);

    confirmOnExit.$inject = ['$state', 'gettext'];
    /* @ngInject */
    function confirmOnExit ($state, gettext) {
        return {
            restrict: 'A',
            require: '^form',
            link: function ($scope, elem, attrs, formCtrl) {
                var allow = false;
                $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                    if (!allow) {
                        if (formCtrl.$dirty) {
                            event.preventDefault();
                            var msg = gettext('You have unsaved work. Sure you want to continue?');

                            bootbox.confirm(msg, function (result) {
                                if (result) {
                                    allow = true;
                                    $state.go(toState.name, toParams);
                                }
                            });
                        }
                    }
                });
            },
        };
    }

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('emptyToNull', function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    ctrl.$parsers.push(function (viewValue) {
                        if (viewValue === '') {
                            return null;
                        }
                        return viewValue;
                    });
                },
            };
        });

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('monAutoFocus', monAutoFocus);

    monAutoFocus.$inject = ['$timeout'];
    /* @ngInject */
    function monAutoFocus ($timeout) {
        return {
            link: function (scope, element, attrs) {
                if (attrs.monAutoFocus === 'focus') {
                    $timeout(function () {
                        element[0].focus();
                    });

                }
            },
        };
    }

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('httpPrefix', function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attrs, controller) {
                    function ensureHttpPrefix (value) {
                        // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
                        if (value && !/^(http)/i.test(value) && 'http'.indexOf(value) === -1) {
                            controller.$setViewValue('http://' + value);
                            controller.$render();
                            return 'http://' + value;
                        } else {
                            return value;
                        }

                    }
                    controller.$formatters.push(ensureHttpPrefix);
                    controller.$parsers.splice(0, 0, ensureHttpPrefix);
                },
            };
        });

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.loading')
        .factory('LoadingService', LoadingService);

    LoadingService.$inject = ['$uibModal', 'gettext'];
    /* @ngInject*/
    function LoadingService ($uibModal, gettext) {

        function service (text) {
            text = text || gettext('Please wait - loading');
            return $uibModal.open({
                template: '<h2 class="text-center">' + text + '</h2>' +
                '<div class="row my-4">' +
                '<div class="col-48 text-center">' +
                '<i class="fa fa-spinner fa-spin fa-3x fa-fw" aria-hidden="true"></i>' +
                '</div>' +
                '</div>',
                backdrop: 'static',


            });
        }

        return service;
    }
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.param')
        .factory('ParamService', ParamService);

    ParamService.$inject = ['$location', '$stateParams'];
    /* @ngInject*/
    function ParamService ($location, $stateParams) {

        var prevParams = {};

        return {
            setParams: setParams,
            returnPrevParam: returnPrevParam,
        };

        // ///////////////

        function setParams (params) {
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    $stateParams[key] = params[key];
                    $location.search(key, params[key]);
                }
            }
            prevParams = params;
        }

        function returnPrevParam () {
            return prevParams;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('checkCheckedCheckbox', CheckboxController);

    CheckboxController.$inject = [];
    /* @ngInject */
    function CheckboxController () {
        return {
            restrict: 'A',
            scope: {
                classVal: '@?',
            },
            link: function ($scope, elem, attrs, formCtrl) {
                elem.on('click', function (e) {
                    var checkedCheckbox = [].slice.call(elem[0].getElementsByTagName('input')).find(function (data) {
                        return data.type === 'checkbox' && data.checked === true;
                    }); var classValue = $scope.classVal || 'active';

                    if (typeof checkedCheckbox !== 'undefined') {
                        if (!elem[0].className.contains(classValue)) {
                            elem[0].className += ' ' + classValue;
                        }
                    } else {
                        if (elem[0].className.contains(classValue)) {
                            elem[0].className = elem[0].className.split(' ' + classValue).join('');
                        }
                    }
                });
            },
        };
    }

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('loadAndDisappear', LoadAndDisappear);

    LoadAndDisappear.$inject = [];
    /* @ngInject */
    function LoadAndDisappear () { // Can only be used on Table rows
        return {
            restrict: 'A',
            link: function ($scope, elem, attrs) {
                var inputField; var spanElement; var collapseContainer; var loading = true;

                [].slice.call(elem[0].getElementsByTagName('td')).forEach(function (element) {
                    collapseContainer = document.createElement('div');

                    [].slice.call(element.childNodes).forEach(function (node) {
                        collapseContainer.appendChild(node);
                    });

                    element.appendChild(collapseContainer);
                });

                spanElement = document.createElement('span');
                spanElement.className = 'fa fa-spinner spin';

                attrs.$observe('setLoad', function (value) {
                    if (value === 'true') {
                        loading = true;
                        inputField = [].slice.call(elem[0].getElementsByTagName('input')).reverse()
                            .find(function (element) {
                                return element.type === 'checkbox';
                            });

                        elem[0].className += ' table-row-load';
                        inputField.className += ' ng-hide';
                        inputField.parentNode.appendChild(spanElement);
                    } else {
                        loading = false;
                        if (elem[0].className.contains('table-row-load')) {
                            elem[0].className = elem[0].className.split(' table-row-load').join('');
                            inputField.className = inputField.className.split(' ng-hide').join('');
                            inputField.parentNode.removeChild(spanElement);
                        }
                    }
                });
            },
        };
    }

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('multipleSelectCheckbox', MultiSelectController);

    MultiSelectController.$inject = [];
    /* @ngInject */
    function MultiSelectController () { // Can only be inside a table
        return {
            restrict: 'A',
            link: function ($scope, elem, attrs) {
                var lastChecked = null;

                elem.on('click', function (e) {
                    if (e.target.type == 'checkbox') {
                        var cell = e.target;
                        do { // Get cell that is containing the clicked input field
                            cell = cell.parentNode;
                        } while (typeof cell.cellIndex === 'undefined');

                        if (cell.nodeName !== 'TH') {
                            if (e.shiftKey) {
                                var $chkboxes = [];
                                [].slice.call(elem[0].getElementsByTagName('tbody')[0].getElementsByTagName('td')) // Find all table cells inside the first tbody
                                    .forEach(function (element) {
                                        if (element.cellIndex === cell.cellIndex) {
                                            $chkboxes.push([].slice.call(element.getElementsByTagName('input')).find(function (element) { // Find ONLY the first checkbox
                                                return element.type === 'checkbox';
                                            }));
                                        }
                                    });

                                var start = $chkboxes.indexOf(e.target);
                                var end = $chkboxes.indexOf(lastChecked);

                                if (start > end) { // For reverse selection
                                    start = [end, end = start][0];
                                }

                                $chkboxes.slice(start, end).forEach(function (element) {
                                    if (!element.checked) {
                                        element.click();
                                    }
                                });
                            }
                            lastChecked = e.target;
                        }
                    }
                });
            },
        };
    }

})();

(function () {
    'use strict';

    angular
        .module('mon.helpers.directives')
        .directive('selectAllCheckbox', SelectAllCheckboxController);

    SelectAllCheckboxController.$inject = [];
    /* @ngInject */
    function SelectAllCheckboxController () { // Can only be set on table
        return {
            restrict: 'A',
            link: function ($scope, elem, attrs) {
                elem.on('click', function (e) {
                    if (e.target.type == 'checkbox') {
                        var tableCell = e.target; var uncheckedFields; var $chkboxes = []; var headerCheckbox;

                        do { // Get cell that is containing the clicked input field
                            tableCell = tableCell.parentNode;
                            headerCheckbox = tableCell.nodeName === 'TH';
                        } while (typeof tableCell.cellIndex === 'undefined');

                        [].slice.call(elem[0].getElementsByTagName('tbody')[0]
                            .getElementsByTagName('td')) // Get cells
                            .forEach(function (element) {
                                if (element.cellIndex === tableCell.cellIndex) {
                                    $chkboxes.push([].slice.call(element.getElementsByTagName('input')) // Get input fields from selected cells
                                        .find(function (element) {
                                            return element.type === 'checkbox';
                                        }));
                                    $chkboxes = $chkboxes.filter(function (element) {
                                        return !element.disabled;
                                    });
                                }
                            });

                        if (headerCheckbox) {
                            $chkboxes.forEach(function (element) {
                                if (element.checked !== e.target.checked) {
                                    element.click();
                                }
                            });
                        } else {

                            uncheckedFields = $chkboxes.filter(function (element) {
                                return !element.checked;
                            });

                            headerCheckbox = [].slice.call(elem[0]
                                .getElementsByTagName('th')[tableCell.cellIndex]
                                .getElementsByTagName('input')) // Find checkbox inside table header
                                .find(function (element) {
                                    return element.type === 'checkbox';
                                });

                            if (!attrs.$attr.selectAllCheckboxIgnoreChildChange || e.target === headerCheckbox) {
                                if (uncheckedFields.length === 0) {
                                    headerCheckbox.checked = true;
                                    headerCheckbox.indeterminate = false;
                                } else if (uncheckedFields.length > 0 && uncheckedFields.length < $chkboxes.length) {
                                    headerCheckbox.indeterminate = true;
                                } else {
                                    headerCheckbox.checked = false;
                                    headerCheckbox.indeterminate = false;
                                }
                            }


                        }
                    }
                });
            },
        };
    }

})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridAction', function () {
        return {
            restrict: 'A',
            link: TableGridActionLinkController,
        };
    });

    function TableGridActionLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-5 col-md-5 col-lg-4');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridCompliance', function () {
        return {
            restrict: 'A',
            link: TableGridComplianceLinkController,
        };
    });

    function TableGridComplianceLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-7 col-md-7 col-lg-5');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridCount', function () {
        return {
            restrict: 'A',
            link: TableGridDateLinkController,
        };
    });

    function TableGridDateLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-3 col-lg-2');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridCrawl', function () {
        return {
            restrict: 'A',
            link: TableGridDateLinkController,
        };
    });

    function TableGridDateLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-3 col-lg-2');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridDate', function () {
        return {
            restrict: 'A',
            link: TableGridDateLinkController,
        };
    });

    function TableGridDateLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-4 col-lg-3');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridIcon', function () {
        return {
            restrict: 'A',
            link: TableGridIconLinkController,
        };
    });

    function TableGridIconLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-4 col-lg-6');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridId', function () {
        return {
            restrict: 'A',
            link: TableGridDateLinkController,
        };
    });

    function TableGridDateLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-3 col-md-3 col-lg-1');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridLanguage', function () {
        return {
            restrict: 'A',
            link: TableGridLanguageLinkController,
        };
    });

    function TableGridLanguageLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-5 col-md-5 col-lg-4');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridName', function () {
        return {
            restrict: 'A',
            link: TableGridLanguageLinkController,
        };
    });

    function TableGridLanguageLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-12 col-md-12 col-lg-10');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridNotification', function () {
        return {
            restrict: 'A',
            link: TableGridNotificationLinkController,
        };
    });

    function TableGridNotificationLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-4 col-lg-17');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridPageView', function () {
        return {
            restrict: 'A',
            link: TableGridPageViewLinkController,
        };
    });

    function TableGridPageViewLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-3 col-md-4 col-lg-4');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridPage', function () {
        return {
            restrict: 'A',
            link: TableGridDateLinkController,
        };
    });

    function TableGridDateLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-5 col-md-5 col-lg-5');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridPolicyType', function () {
        return {
            restrict: 'A',
            link: TableGridIconLinkController,
        };
    });

    function TableGridIconLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-8 col-md-3 col-lg-1');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridPriority', function () {
        return {
            restrict: 'A',
            link: TableGridPriorityLinkController,
        };
    });

    function TableGridPriorityLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-4 col-lg-4');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridRemoveFromDictionary', function () {
        return {
            restrict: 'A',
            link: TableGridremoveFromDictionaryLinkController,
        };
    });

    function TableGridremoveFromDictionaryLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-5 col-md-5 col-lg-8');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridRole', function () {
        return {
            restrict: 'A',
            link: TableGridRoleLinkController,
        };
    });

    function TableGridRoleLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-4 col-lg-2');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridStatus', function () {
        return {
            restrict: 'A',
            link: TableGridStatusLinkController,
        };
    });

    function TableGridStatusLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-5 col-md-4 col-lg-4');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridTitle', function () {
        return {
            restrict: 'A',
            link: TableGridTitleLinkController,
        };
    });

    function TableGridTitleLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-20 col-md-20 col-lg-20');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridUserDate', function () {
        return {
            restrict: 'A',
            link: TableGridUserDateLinkController,
        };
    });

    function TableGridUserDateLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-8 col-md-7 col-lg-4');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridViolation', function () {
        return {
            restrict: 'A',
            link: TableGridViolationLinkController,
        };
    });

    function TableGridViolationLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-4 col-md-4 col-lg-3');
    }
})();

(function () {
    'use strict';
    angular.module('mon.helpers.directives').directive('tableGridWord', function () {
        return {
            restrict: 'A',
            link: TableGridWordLinkController,
        };
    });

    function TableGridWordLinkController (scope, elem, attrs, ctrls) {
        elem.addClass('col-sm-20 col-md-20 col-lg-20');
    }
})();
